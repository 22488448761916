.settings {
    padding-bottom: 50px;

    &__section-title {
        margin-top: 30px;
        color: var(--color-grey-text);
        font-size: 20px;
        font-weight: bold;
    }

    &__section {
        margin-top: 30px;
    }

    &__column {
        display: flex;
        width: 33%;
        flex-direction: column;
        padding: 0 4px;

        &_large {
            width: 66%;
        }

        &:first-child {
            margin-left: -4px;
        }

        &:last-child {
            margin-right: -4px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 30px;
    }

    &__column-count-4 {
        column-count: 4;
    }

    &__field {
        width: 100%;
    }
}

.field-settings-table {
    width: 100%;
    table-layout: fixed;

    thead tr th,
    tbody tr td {
        text-align: left;
    }

    & &__row#{&}__row_assertive {
        background-color: var(--color-assertive);
    }

    // TODO: унификация стилей для сорта
    &__sort-title {
        color: var(--color-primary);
        font-weight: 700;
    }

    th:last-child {
        width: 150px;
        text-align: center;
    }

    th:nth-child(3) {
        text-align: center;
    }

    td:nth-child(n + 3) {
        text-align: center;
    }
}

.input-search {
    max-width: 800px;
}

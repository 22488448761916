.users-table {
    table-layout: fixed;

    thead tr th {
        @include text-overflow;

        text-align: left;

        & > span {
            justify-content: flex-start;
        }

        &:nth-last-child(-n + 2) {
            text-align: center;

            & > span {
                justify-content: center;
            }
        }

        &:nth-last-child(1) {
            width: 40px;
        }
    }

    tbody tr td {
        @include text-overflow;

        text-align: left;

        &:nth-last-child(-n + 2) {
            text-align: center;
        }

        &:nth-last-child(1) {
            padding: unset;
            vertical-align: middle;
        }
    }
}

.lock-icon {
    width: 20px;
    height: 20px;
    color: var(--color-grey-placeholder);
}

.progress-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $grid-size * 4;
}

.progress-message {
    max-width: 200px;

    &:empty {
        display: none;
    }
}

.progress-dimmer {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
}

.progress-icon {
    width: 40px;
    height: 40px;
    animation: rotation 2s infinite linear;
    color: $primaryColor;

    &_small {
        width: 1rem;
        height: 1rem;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.button {
    &_block {
        display: block;
    }

    &_icon {
        color: var(--color-grey-icon);

        & i {
            display: block;
        }

        &:hover {
            color: $primaryColor;
        }

        &:focus {
            color: $primaryColor;
        }
    }
}

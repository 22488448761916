.additional-weights-table {
    table-layout: fixed;

    thead tr th {
        @include text-overflow;

        text-align: center;

        & > span {
            justify-content: center;
        }

        &:first-child {
            text-align: left;

            & > span {
                justify-content: flex-start;
            }
        }
    }

    tbody tr.additional-weights-table_over-limit {
        background-color: var(--color-assertive-background);

        &:nth-child(odd) {
            background-color: var(--color-assertive-background-hovered);
        }

        &:hover {
            background-color: var(--color-assertive-hovered);
        }
    }

    tbody tr td {
        @include text-overflow;

        cursor: pointer;
        text-align: center;

        &:hover {
            box-shadow: inset 0 0 2px var(--color-black);
        }

        &:first-child {
            text-align: left;
        }
    }
}

@mixin input-focused {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
    outline: 0 none;
    outline-offset: 0;
}

@mixin input-hover {
    background-color: var(--color-grey-1);
}

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-manager-table {
    table-layout: fixed;

    thead {
        tr {
            th {
                &:nth-child(1) {
                    width: 0;
                }

                &:nth-child(2) {
                    text-align: left;
                }

                &:nth-child(3) {
                    width: 400px;
                }

                &:nth-child(4) {
                    width: 200px;
                }

                &:nth-child(5) {
                    width: 200px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                height: 46px;
                min-height: 46px;
                vertical-align: middle;

                &:nth-child(2) {
                    text-align: left;
                }

                &:nth-child(3) {
                    padding: 0;
                }
            }
        }
    }
}

.file-manager {
    &__info {
        min-width: 360px;
        padding: 0 20px 16px;
        border-left: 1px solid var(--color-grey-border);
        overflow-y: auto;
    }

    tbody {
        .file-manager__row {
            &_selected {
                background-color: var(--color-primary);
                color: var(--color-white);

                &:nth-child(odd) {
                    background-color: var(--color-primary);
                }

                &:hover {
                    background-color: var(--color-primary);
                }
            }
        }
    }

    &__upload-dialog {
        &_content {
            position: sticky;
            z-index: 10;
            top: 0;
            background-color: var(--color-white);
        }

        &_bottom-line {
            &:not(:last-child) {
                border-bottom: 1px solid var(--color-grey-border);
            }
        }

        &_toggle {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    &__upload-panel {
        width: 440px;
        padding: 4px;
    }

    &__count {
        cursor: default;
    }

    &__warning-icon {
        width: 60px;
        height: 60px;
        color: var(--color-assertive);
    }
}

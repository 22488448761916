.tree {
    --level: 0;
    --tree-indent: 32px;

    color: var(--color-grey-text);
    font-size: 0.875rem;
    line-height: 1.5rem;

    &__item-container {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        background-color: var(--color-white);
        background-position: 100%;
        cursor: pointer;
        transition: background-position 0.5s;

        &_active {
            background-color: var(--color-primary-background);
        }

        &:hover {
            background-color: var(--color-primary-background);
        }

        & > button {
            line-height: inherit;
        }
    }

    &__item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 8px 16px 8px
            calc(8px + var(--tree-indent) * (var(--level) + 1));
        gap: 8px;

        &_checkbox {
            height: 54px;
        }

        &_gap-4 {
            gap: 16px;
        }
    }

    &__drop-zone {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 8px;
        margin-top: -4px;
        margin-bottom: -4px;
        background-position: 100%;
        transition: background-position 0.5s;
    }

    &__drag-n-drop {
        position: absolute;
        top: 50%;
        left: 0;
        opacity: 0;
        transform: translateY(-50%);
    }

    &__item-container:hover &__drag-n-drop {
        opacity: 1;
    }

    &__drag-n-drop-icon {
        display: block;
        width: 24px;
        height: 24px;
        color: var(--color-grey-icon);
    }

    &__toggle {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        color: var(--color-grey-icon);

        &_disabled {
            color: var(--color-grey-disabled);
        }
    }

    &__title {
        display: flex;
        overflow: hidden;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--color-grey-text);
        gap: 16px;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;

        &_small-gap {
            gap: 4px;
        }

        &_checkbox {
            width: 100%;
        }

        &_icon {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    & & &__title {
        font-weight: 700;
        text-transform: unset;

        &_item {
            font-weight: 400;
        }
    }

    &__item-container:disabled &__toggle {
        color: var(--color-grey-disabled);
    }

    &_drag-active &__drop-zone_enable,
    &_drag-active &__item-container_drop-enable {
        background: var(--color-primary-background);
        background: linear-gradient(
            90deg,
            var(--color-primary-background) 0%,
            var(--color-primary-background) 50%,
            var(--color-white) 100%
        );
        background-position: 100%;
        background-size: 400% 100%;
    }

    &_drag-active &__drop-zone_enable {
        z-index: 2;
    }

    &_drag-active &__drop-zone_active,
    &_drag-active &__item-container_drop-active {
        background-position: 0%;
    }
}

.calendar {
    &__overlay {
        position: fixed;
        z-index: 100000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: var(--color-grey-overlay);
    }
}

.cluster-view {
    &__header {
        padding: 15px;
        background-color: var(--color-white);
    }

    &__content {
        height: 100%;
        padding: 15px;
        background-color: var(--color-grey-background);
    }
}

.cluster {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);

    &__header {
        padding: $grid-size * 6;
    }

    &__keywords {
        min-height: 300px;
        padding: $grid-size * 6;
        border-left: 1px solid var(--color-grey-disabled);
    }

    &__footer {
        display: flex;
        flex-direction: row;
        padding: $grid-size * 6;
        gap: $grid-size * 10;
    }
}

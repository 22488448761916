.logo {
    width: 102px;
    height: 30px;
    background-image: url('../assets/images/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &_small {
        width: 30px;
        background-image: url('../assets/images/logo_square.svg');
    }
}

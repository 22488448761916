/* stylelint-disable no-descending-specificity */
.entity {
    &__item {
        height: 40px;
        padding-left: 80px;
        cursor: pointer;

        &:hover {
            background-color: var(--color-grey-border);
        }

        &_selected {
            background-color: var(--color-grey-border);
        }
    }
}

.entity-table {
    width: 100%;
    table-layout: fixed;

    &__empty {
        font-size: 40px;
    }

    thead tr th,
    tbody tr td {
        text-align: left;

        &:nth-child(1) {
            width: 250px;
        }
    }

    thead tr th {
        & > span {
            justify-content: flex-start;
        }
    }
}

.entity-dialog-table {
    thead tr th {
        padding: 20px 0;
    }

    thead tr th,
    tbody tr td {
        vertical-align: middle;

        &:nth-child(1) {
            width: 40px;
        }

        &:nth-child(2) {
            text-align: left;
        }

        &:nth-last-child(1) {
            text-align: center;
        }
    }

    tr:hover {
        background-color: var(--color-grey-background);
    }
}

.speaker-tree {
    width: 100%;

    &__item-container {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        background-color: var(--color-white);
        background-position: 100%;
        cursor: pointer;
        transition: background-position 0.5s;

        &:hover {
            background-color: var(--color-primary-background);
        }

        &_read-only {
            cursor: default;

            &:hover {
                background-color: transparent;
            }
        }

        & > button {
            line-height: inherit;
        }
    }

    &_fix-height {
        overflow: auto;
        max-height: 70vh;
    }

    &__toggle-button {
        display: flex;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        color: var(--color-grey-icon);

        &_read-only {
            cursor: default;
        }
    }

    &__weight-input {
        width: 160px;
        min-width: 160px;
        max-width: 160px;
    }
}

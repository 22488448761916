.header-menu {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    font-weight: 400;

    &__icon {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 1.25rem;
    }

    &__notifications {
        position: absolute;
        top: 6px;
        right: 10px;
        padding: 2px 4px;
        border-radius: 7px;
        background: var(--color-green-hover);
        color: var(--color-white);
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
    }

    &_main {
        flex: 1 1 auto;
        padding: 0 30px;
    }

    &_right {
        padding: 0;
    }

    &__item {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 15px;
        margin: 0 15px;
        color: var(--color-white);
        cursor: default;
        font-size: 15px;
        line-height: 40px;
        text-decoration: none;
        white-space: nowrap;

        &_composite {
            padding: 0;
        }

        &_clear {
            margin: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &_button {
            cursor: pointer;
        }

        &_active {
            background: var(--color-primary-dark);
        }

        &:visited {
            color: var(--color-white);
        }

        &:hover,
        &:visited:hover {
            background: var(--color-primary-darkest);
            color: var(--color-white);
        }
    }

    &__item-select {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 15px;
        font-size: 15px;
        line-height: 40px;
        white-space: nowrap;
    }

    &__overlay {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        background: var(--color-grey-overlay);
    }

    &__item_opened &__overlay {
        display: block;
    }

    &__item-arrow {
        margin-left: 15px;
        color: var(--color-white);
        font-size: 12px;
    }

    &__submenu {
        position: absolute;
        z-index: 10000;
        top: 100%;
        left: 0;
        display: none;
        padding: 0.5rem 0;
        border-radius: 2px;
        background-color: var(--color-white);
        box-shadow: 0 0 8px var(--color-grey-placeholder);
        font-size: 0.875rem;
        line-height: 1.125rem;

        &_left-side {
            right: 0;
            left: unset;
        }
    }

    &__item_opened &__submenu {
        display: block;
    }

    &__submenu-item {
        display: block;
        width: 100%;
        padding: 0.4375rem 1.5rem;
        color: var(--color-grey-text);
        cursor: pointer;
        text-align: left;
        text-decoration: none;
        white-space: nowrap;

        &:visited {
            color: var(--color-grey-text);
        }

        &:hover,
        &:hover:visited {
            background: var(--color-primary-background-hovered-light);
            color: var(--color-primary);
        }

        &_active,
        &_active:visited {
            background: var(--color-primary-background);
            color: var(--color-primary);
        }
    }

    &__user-info {
        padding: 12px 20px;
        border-bottom: 1px solid var(--color-grey-border);
        margin-bottom: 7px;
        color: var(--color-grey-text);
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
    }
}

.label {
    padding: 2px 15px;
    border-radius: 18px;
    color: var(--color-white);
    font-size: 12px;
    line-height: 18px;

    &__name {
        filter: brightness(0.5);
    }

    &__icon {
        font-size: 11px;
    }
}

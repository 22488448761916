.help-clusters {
    width: 100%;

    th {
        padding-top: 20px;
        padding-bottom: 35px;
        font-weight: bold;
        text-align: left;

        &:nth-child(2) {
            padding: 24px 60px;
        }

        &:nth-child(3) {
            min-width: 300px;
            text-align: center;
        }
    }

    td {
        padding: 24px 0;
        vertical-align: top;

        &:nth-child(2) {
            padding: 24px 60px;
        }

        &:nth-child(3) {
            vertical-align: middle;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #e4e7f2;
        }
    }

    &__link {
        display: none;
    }

    &__area:hover {
        .help-clusters__link {
            display: block;
        }
    }
}

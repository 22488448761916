.badges-crop-table {
    tr {
        th,
        td {
            line-height: 1rem;
            vertical-align: middle;
            white-space: nowrap;

            &:nth-child(1) {
                width: 48px;
            }

            &:nth-child(2) {
                width: 64px;
            }
        }
    }

    // TODO: надо подумать над дефолтными стилями таблиц - а то что-то их переопределение вызывает боль
    & tbody tr#{&}__row {
        &_invalid {
            background-color: var(--color-assertive-background);
        }
    }

    & tbody tr td#{&}__cell {
        &_custom {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.badges-crop-control {
    width: 100%;
    text-align: left;
}

.badges-crop-title {
    width: 100%;
    padding: 8px;
    font-weight: bold;
    text-align: center;
}

.badges-crop-selection {
    padding: 0 16px 16px;
}

.badge-bounds-panel {
    height: 100%;
}

.badge-panels {
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-wrap: nowrap;
}

.badge-panel {
    display: flex;
    width: 50%;
    height: 100%;
    flex-flow: column nowrap;

    &:first-child {
        border-right: 1px solid var(--color-grey-border);
    }
}

.badge-table-container {
    overflow: auto;
    flex: 1 1 auto;
}

.badge-footer {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2.5rem;
    box-shadow: 0 0 10px rgba(224 224 224 / 40%);
    gap: 0.5rem;

    &__error {
        flex-shrink: 0;
    }

    button {
        margin: unset;
    }
}

.document-header {
    display: flex;
    align-items: center;

    &__title {
        flex: 1 1 auto;
        color: var(--color-grey-text);
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
    }
}

.document-fields {
    gap: 0 32px;
}

.document-field {
    max-width: 500px;
    line-height: 40px;
}

.document-player {
    margin-top: 8px;
}

.document-label {
    display: inline-block;
    padding: 0 8px;
    border-radius: 12px;
    margin: 0 2px;
    color: var(--color-white);
    font-size: small;
    line-height: 24px;
}

.document-table-tooltip {
    max-width: 400px;
}

.document-labels {
    &__labels {
        display: flex;
        min-height: 24px;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px 0;
        color: var(--color-white);
    }

    &__label {
        display: inline-block;
        height: 24px;
        padding: 0 12px;
        border-radius: 12px;
        font-size: 12px;
        line-height: 24px;
    }

    &__button {
        color: var(--color-grey-text);
        font-size: 12px;
    }

    &__button-icon {
        width: 20px;
        height: 20px;
        color: var(--color-grey-icon);
    }
}

.table {
    width: 100%;
    font-size: 0.875rem;

    &_fixed {
        table-layout: fixed;
    }

    th {
        position: sticky;
        z-index: 1;
        top: 0;
        padding: 16px;
        background-color: var(--color-white);
        color: var(--color-grey-text);
        font-weight: 700;
        text-align: center;
        vertical-align: middle;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid var(--color-grey-border);
            content: '';
        }

        & > span {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 15px;
        }

        &.table_left {
            text-align: left;
        }
    }

    td {
        padding: 16px;
        text-align: center;

        &.table_left {
            text-align: left;
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: var(--color-grey-background);
            }

            &:hover {
                background-color: var(--color-grey-border);
            }
        }
    }
}

.table-na {
    background-color: var(--color-grey-title);
}

.copy-button {
    width: 40px;
    height: 40px;
    padding: 7px;
}

.copy-button-icon {
    width: 17px;
    height: 17px;
    margin: auto;
    color: var(--color-white);
}

.copy-label-offset {
    margin-left: 30px;
}

.copy-label-gap {
    row-gap: 85px;
}

.gradient-preview {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;

    &__icon {
        width: 19px;
        height: 15px;
    }
}

.am5exporting-type-separator {
    border: none !important;
}

.am5exporting-label-alt {
    display: none !important;
}

.am5exporting-icon {
    border-radius: $borderRadius !important;
}

.am5exporting-icon:focus,
.am5exporting-icon:hover,
.am5exporting-menu-open .am5exporting-icon {
    background: rgba($buttonBg, $textButtonHoverBgOpacity) !important;
}

.am5exporting-menu {
    z-index: 1 !important;
}

.am5exporting-menu path {
    fill: var(--color-grey-icon) !important;
}

.am5exporting-menu .am5exporting-icon:focus path,
.am5exporting-menu .am5exporting-icon:hover path {
    fill: var(--color-primary) !important;
}

.am5exporting-list {
    padding: $verticalMenuPadding !important;
    border: $overlayMenuBorder !important;
    border-radius: $borderRadius !important;
    background: $overlayMenuBg !important;
    background-color: var(--color-white) !important;
    box-shadow: $overlayMenuShadow !important;
    color: $menuTextColor !important;
}

.am5exporting-item:not(.am5exporting-type-format) {
    display: none !important;
}

.am5exporting-item a {
    padding: $menuitemPadding !important;
    border-radius: $menuitemBorderRadius !important;
    color: $menuitemTextColor !important;
    transition: $listItemTransition !important;
    user-select: none !important;
}

.am5exporting-item a:empty {
    display: none;
}

.am5exporting-item a:hover,
.am5exporting-item a.am5exporting-item-active {
    background: $menuitemHoverBg !important;
    color: $menuitemTextActiveColor !important;
}

.drop-zone {
    position: relative;
    border: 1px dashed var(--color-grey-border);
    background-color: var(--color-primary-background-light);
    padding: 20px;
    transition: all 0.5s;
    &_drag-over {
        border-color: var(--color-primary-active);
    }
}

.input-file {
    position: absolute;
    top: 0;
    left: 100%;
    cursor: pointer;
    opacity: 0;

    &_wrapped {
        width: 0;
        height: 0;
    }

    &_large {
        left: 0;
        width: 100%;
        height: 100%;
    }

    &_small {
        width: 30%;
    }
}
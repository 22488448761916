.document-comments {
    --custom-width: 320px;

    width: 320px;
    min-width: (var(--custom-width));
    max-width: (var(--custom-width));

    &__comment {
        &:not(.document-comments__comment_edited):hover {
            background-color: var(--color-primary-background);
        }
    }

    &__reply {
        min-width: 0;
        padding-left: 4 * $grid-size;
        border-left: 3px solid #c4c4c4;

        &:disabled {
            cursor: default;
        }
    }

    &__reply-icon {
        transform: scale(-1, 1);
    }

    &__cancel-icon {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &__edit {
        position: sticky;
        bottom: 0;
        width: 100%;
        height: fit-content;
        padding-bottom: 10 * $grid-size;
        border-top: 1px solid var(--color-grey-border);
        background-color: var(--color-white);

        &_textarea {
            width: 100%;
            height: 100%;
            max-height: 40 * $grid-size;
            padding: 4 * $grid-size 4 * $grid-size 0 4 * $grid-size;
            border: none;
            border-radius: 0;
            margin: 0;

            &:enabled:focus {
                box-shadow: none;
            }
        }

        // TODO убрать после добавления модификатора box и соответственно clear-offset в .grid
        &_links {
            padding: 4 * $grid-size 4 * $grid-size 0 4 * $grid-size;
        }
    }

    &__toggle {
        position: absolute;
        z-index: 12;
        top: $grid-size * 5;
        right: $grid-size * 6;
    }

    &__icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

    &__send-button {
        position: absolute;
        right: $grid-size * 2;
        bottom: $grid-size * 2;
    }

    &__header {
        position: sticky;
        z-index: 10;
        top: 0;
        display: grid;
        height: 56px;
        min-height: 56px;
        padding: $grid-size * 4;
        padding-left: calc((var(--custom-width)) * 32px);
        background-color: var(--color-white);
        grid-template-columns:
            calc((var(--custom-width)) / 5)
            calc((var(--custom-width)) / 2)
            calc((var(--custom-width)) / 5);
    }

    &__header-icon {
        width: 16px;
        height: 16px;
    }

    &__header-title {
        padding: 0 6 * $grid-size;
    }
}

.document-comments_hidden {
    min-width: 0;
    max-width: 0;

    & > .document-comments-header {
        overflow: hidden;
        padding: 0;
    }
}

.document-comments-groups {
    &__group {
        padding: $grid-size * 2 $grid-size * 4;
        cursor: pointer;

        &:hover {
            background-color: var(--color-primary-background);
        }
    }

    &__badge {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: -8px;
        background-color: var(--color-primary);
        color: var(--color-white);
        font-size: small;
        line-height: 20px;
    }
}

.comment-links {
    &:empty {
        display: none;
    }
}

.comment-link {
    display: flex;
    min-width: 0;
    align-items: center;
    justify-content: center;

    &__icon {
        width: 22px;
        height: 14px;
        padding-left: $grid-size * 2;
        color: var(--color-assertive);
    }

    &_editing {
        justify-content: space-between;
    }
}

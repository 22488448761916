.document-history {
    &__list {
        padding: 0 $grid-size * 4;
        overflow-y: auto;
    }

    &__item {
        padding-bottom: 8px;
        border-bottom: 1px solid var(--color-grey-border);
        grid-template-columns: 1fr 2fr;

        &:last-child {
            border-bottom: none;
        }

        &_date {
            width: 144px;
        }

        div {
            line-height: 24px;
        }
    }
}

.authority-edit {
    position: relative;

    &__heading {
        position: sticky;
        z-index: 100;
        top: 0;
        padding-bottom: 0.5rem;
        background-color: var(--color-white);
    }
}

.message {
    &__icon {
        width: 32px;
        height: 32px;
        flex-shrink: 0;

        &_error {
            color: var(--color-assertive);
        }

        &_success {
            color: var(--color-green-main);
        }

        &_info {
            color: var(--color-primary);
        }

        &_warn {
            color: var(--color-orange);
        }
    }
}

.array-label-with-actions {
    &__item {
        display: inline-block;
        max-width: 115px;
        padding: 0 12px;
        border: 1px solid var(--color-grey-border);
        border-radius: 12px;
        background: white;
        color: var(--color-grey-placeholder);
        font-size: 12px;
        line-height: 24px;
        text-wrap: nowrap;
    }

    &__count {
        color: var(--color-primary);
        line-height: inherit;
    }
}

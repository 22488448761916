::-webkit-scrollbar {
    width: $scroll-size-width;
    height: $scroll-size-height;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--color-grey-disabled);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-grey-icon);
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus :-webkit-autofill,
:-webkit-autofill:hover :-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
    animation-duration: 1s !important;
    animation-fill-mode: both !important;
    animation-name: autofill !important;
}

* {
    box-sizing: border-box;
}

html,
body {
    color: var(--color-grey-text);
    font-family: var(--default-font);
    font-size: var(--default-font-size);
}

button {
    padding: 0;
    border: unset;
    background: unset;
    color: unset;
    cursor: pointer;
    font-family: var(--default-font);
    font-size: 0.875rem;
    text-align: left;

    &:focus {
        outline: unset;
    }
}

a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
        color: var(--primary-color);
    }

    &:active {
        color: var(--primary-color);
    }

    &:visited {
        color: var(--primary-color);
    }

    &:focus {
        color: var(--primary-color);
    }
}

.progress {
    position: absolute;
}

.page-wrapper {
    height: 100%;
    flex: 1 1 100%;
    overflow-y: auto;
}

.overflow {
    overflow: auto;

    &_y-auto {
        overflow-x: visible;
    }

    &_x-auto {
        overflow-y: visible;
    }

    &_hidden {
        overflow: hidden;
    }
}

.input-wrapper {
    border: 1px solid var(--color-grey-border);
    border-radius: 5px;

    &__divided {
        border-left: 1px solid var(--color-grey-border);
    }
}

.input {
    padding: 0 1rem;
}

.filter {
    & &__title {
        margin-bottom: 1rem;
        color: var(--color-grey-placeholder);
        font-weight: bold;
    }

    & &_section {
        margin-top: 2rem;
    }
}

.drawer {
    height: 100%;

    &_wrapper {
        width: 25%;
    }

    &_container {
        background-color: var(--color-grey-background);
    }
}

.hoverable {
    &__highlight:hover,
    &:hover &__highlight {
        background-color: var(--color-grey-background);
    }

    &__target {
        visibility: hidden;
    }

    &:hover &__target {
        visibility: visible;
    }

    &_button {
        cursor: pointer;
    }

    &_active {
        background-color: var(--color-grey-background);
    }
}

.selected-row {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.progress-value {
    font-size: 0.675rem;
}

.uppercase {
    text-transform: uppercase;
}

.hover {
    &__show {
        opacity: 0;
    }

    &:hover .hover {
        &__show {
            opacity: 1;
        }
    }
}

h5 {
    color: var(--color-grey-title);
    font-size: 0.875rem;
    font-weight: 700;
}

.no-events {
    pointer-events: none;
}

.show-one-of-two {
    &:not(:hover) > *:nth-child(2) {
        display: none !important;
    }

    &:hover > *:nth-child(1) {
        display: none !important;
    }
}

.pulse {
    animation: 1s pulse-primary;
}

.input-alert-pulse {
    animation: 0.3s input-alert-pulse;
}

.highlight-primary {
    animation: 1s highlight-primary;
}

.hr {
    &_grey-light {
        height: 1px;
        border: none;
        background-color: var(--color-grey-border);
    }
}

@keyframes pulse-primary {
    0% {
        box-shadow: 0 0 0 0 var(--color-grey-icon);
    }

    70% {
        box-shadow: 0 0 0 10px var(--color-grey-icon);
    }

    100% {
        box-shadow: none;
    }
}

@keyframes input-alert-pulse {
    0% {
        border-color: var(--color-primary);
    }

    70% {
        border-color: var(--color-assertive);
    }

    100% {
        border-color: var(--color-primary);
    }
}

@keyframes highlight-primary {
    0% {
        background-color: var(--color-primary-background-hovered);
    }

    70% {
        background-color: var(--color-primary-background-hovered);
    }

    100% {
        background-color: none;
    }
}

.highlighted-word {
    background-color: var(--color-green-highlight);
}

.transcript {
    padding: 20px 20%;

    @media (max-width: 1440px) {
        padding: 20px 5%;
    }

    &_test-mode {
        padding: 0;
    }
}

.phrase {
    position: relative;
    max-width: 70%;
    align-self: center;
    padding: 20px 24px;
    border-radius: 30px 30px 30px 0;
    background: var(--color-primary-background);
    color: var(--color-grey-text);
    font-size: 14px;
    word-break: break-word;

    &_testing-multi {
        max-width: 100%;
    }

    &_note {
        border: 1px solid var(--color-grey-border);
        background: var(--color-white);
    }

    &_right {
        right: 0;
        border-radius: 30px 30px 0;
        background: var(--color-grey-background);
    }

    &_system {
        border-radius: 30px;
        background: var(--color-gold-background);
    }

    &_interrupt {
        background: var(--color-assertive-background);
    }

    &__source {
        overflow: hidden;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__time {
        flex-shrink: 0;
        color: var(--color-grey-placeholder);
        font-size: 14px;
    }

    &__text {
        line-height: 1.2;

        span.term {
            background-color: var(--color-primary-background-hovered);

            &_active {
                background-color: var(--color-primary-background-hovered-dark);
            }
        }

        span.current {
            animation-fill-mode: none;
            background-color: var(--color-gold-hover);
            color: var(--color-grey-text);
            transition: background-color 800ms, color 800ms;
        }

        span.current.highlighted-word {
            background-color: var(--color-green-highlight);
            color: var(--color-grey-text);
        }

        span[correct] {
            position: relative;

            &::after {
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 4px;
                // TODO: не получилось вынести в assets - в вебпаке нужно настраивать что-то для Inline svg.
                // нужно вынести в отдельный файл и заменить red на currentColor, а в after подставить color: var(--color-assertive)
                background-image: url('data:image/svg+xml;utf8,<svg width="46" height="20" viewBox="0 0 46 20" fill="red" xmlns="http://www.w3.org/2000/svg"><path d="M46 6.01709V0.0107422C45.8027 0.00341797 45.6035 0 45.4004 0C38.2998 0 34.5996 4.5 31.7002 8.1001C28.7998 11.7998 26.7002 14 22.7002 14C18.7002 14 16.7002 11.7998 13.7002 8.1001L13.5186 7.88184C10.5537 4.32129 6.95703 0 0 0V6C4 6 6 8.2002 9 11.8999L9.18262 12.1191C12.1475 15.6802 15.7432 20 22.7002 20C29.7998 20 33.5 15.5 36.4004 11.8999C39.2998 8.2002 41.4004 6 45.4004 6C45.6055 6 45.8047 6.00586 46 6.01709Z"/></svg>');
                background-position: bottom;
                background-repeat: repeat-x;
                background-size: contain;
                color: var(--color-assertive);
                content: '';
            }
        }
    }

    &__image {
        max-width: 150px;
        max-height: 150px;
        border-radius: 10px;
        margin-top: 5px;
        box-shadow: 1px 1px 10px 1px #0001;
        object-fit: cover;
    }

    &__tags {
        position: absolute;
        z-index: 1;
        bottom: -14px;
        left: 0;
        width: unset;
        padding: 2px;
        border-radius: 14px;
        background-color: var(--color-white);
        box-shadow: 0 0 8px rgb(0 0 0 / 25%);
        transform: translateX(-50%);

        &_right {
            right: 0;
            left: unset;
            transform: translateX(50%);
        }
    }

    &__tag {
        display: block;
        width: 24px;
        height: 24px;
        color: var(--color-grey-icon);

        &_negative {
            color: var(--color-assertive);
        }

        &_positive {
            color: var(--color-green-main);
        }
    }

    &_testing {
        background-color: var(--color-gold-hover);
    }

    &__train {
        max-width: 400px;
    }
}

.dialog {
    min-width: 400px;
    max-width: 50vw;

    &_small {
        max-width: 25vw;
    }

    &_small-fix {
        width: 25vw;
        max-width: 25vw;
    }

    &_medium {
        max-width: 50vw;
    }

    &_medium-fix {
        width: 50vw;
        max-width: 50vw;
    }

    &_large {
        max-width: 75vw;
    }

    &_large-fix {
        width: 75vw;
        max-width: 75vw;
    }

    &_small-fix-height {
        height: 25vh;
    }

    &_medium-fix-height {
        height: 50vh;
    }

    &_large-fix-height {
        height: 75vh;
    }

    &_full {
        max-width: 100%;
    }

    &_full-fix-height {
        height: 100%;
        max-height: 100%;
    }

    &__header {
        &_overflow {
            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &__form {
        display: flex;
        overflow: auto;
        flex: 1 1 auto;
        flex-direction: column;
    }

    &__content {
        display: flex;
        overflow: auto;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 0 2.5rem 2.5rem;

        &_clear {
            padding: 0;
        }

        &_noscroll {
            overflow: hidden;
        }
    }

    &__footer {
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem 2.5rem;
        box-shadow: 0 0 10px rgba(224 224 224 / 40%);
        gap: 0.5rem;

        button {
            margin: unset;
        }
    }
}

.tab {
    padding-bottom: 4px;
    border-bottom: 2px solid var(--color-grey-disabled);
    cursor: pointer;
    transition: all 0.2s linear;

    &_active {
        border-bottom-color: var(--color-primary);
        cursor: default;
    }

    &:hover {
        border-bottom-color: var(--color-primary-hovered);
    }

    &:active {
        border-bottom-color: var(--color-primary-active);
    }

    &:focus {
        border-bottom-color: var(--color-primary-focus);
    }

    &:disabled {
        color: var(--color-grey-disabled);
        cursor: default;

        &:hover {
            border-bottom-color: var(--color-grey-disabled);
        }
    }
}

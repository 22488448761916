$pathRoboto: #{'../assets/fonts/Roboto/'};

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    src: local('Roboto- Thin'), local('Roboto-Thin'),
        url($pathRoboto + 'Roboto-thin.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-thin.woff') format('woff'),
        url($pathRoboto + 'Roboto-thin.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    src: local('Roboto- Thin Italic'), local('Roboto-ThinItalic'),
        url($pathRoboto + 'Roboto-thin-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-thin-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-thin-italic.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: local('Roboto- Light'), local('Roboto-Light'),
        url($pathRoboto + 'Roboto-light.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-light.woff') format('woff'),
        url($pathRoboto + 'Roboto-light.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    src: local('Roboto- Light Italic'), local('Roboto-LightItalic'),
        url($pathRoboto + 'Roboto-light-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-light-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-light-italic.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto-'), local('Roboto-Regular'),
        url($pathRoboto + 'Roboto.woff2') format('woff2'),
        url($pathRoboto + 'Roboto.woff') format('woff'),
        url($pathRoboto + 'Roboto.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    src: local('Roboto- Italic'), local('Roboto-Italic'),
        url($pathRoboto + 'Roboto-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-italic.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: local('Roboto- Medium'), local('Roboto-Medium'),
        url($pathRoboto + 'Roboto-medium.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-medium.woff') format('woff'),
        url($pathRoboto + 'Roboto-medium.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    src: local('Roboto- Medium Italic'), local('Roboto-MediumItalic'),
        url($pathRoboto + 'Roboto-medium-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-medium-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-medium-italic.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: local('Roboto- Bold'), local('Roboto-Bold'),
        url($pathRoboto + 'Roboto-bold.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-bold.woff') format('woff'),
        url($pathRoboto + 'Roboto-bold.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    src: local('Roboto- Bold Italic'), local('Roboto-BoldItalic'),
        url($pathRoboto + 'Roboto-bold-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-bold-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-bold-italic.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    src: local('Roboto- Black'), local('Roboto-Black'),
        url($pathRoboto + 'Roboto-black.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-black.woff') format('woff'),
        url($pathRoboto + 'Roboto-black.ttf') format('truetype');
}
@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    src: local('Roboto- Black Italic'), local('Roboto-BlackItalic'),
        url($pathRoboto + 'Roboto-black-italic.woff2') format('woff2'),
        url($pathRoboto + 'Roboto-black-italic.woff') format('woff'),
        url($pathRoboto + 'Roboto-black-italic.ttf') format('truetype');
}

.stage-item {
    cursor: pointer;

    &:hover {
        background-color: var(--color-primary-background);
        color: var(--color-primary);
    }

    &_active {
        background-color: var(--color-primary-background);
        color: var(--color-primary);
    }
}

.stage-color {
    width: 16px;
    height: 16px;
}

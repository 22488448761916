.card {
    padding: 32px;
    border-radius: $borderRadius;
    background-color: var(--color-grey-background);

    &__title {
        color: var(--color-grey-text);
        font-size: 16px;
        font-weight: 700;
    }

    &__subtitle {
        color: var(--color-grey-placeholder);
        font-size: 14px;
    }

    &__item {
        color: var(--color-grey-text);
        font-size: 14px;
    }
}

.tag {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    background-color: var(--color-primary-background);
    color: var(--color-primary);
    gap: $grid-size * 2;

    &__title {
        padding: $grid-size $grid-size * 2;
        font-size: 12px;
    }

    &__button {
        display: flex;
        padding: $grid-size;

        &:hover {
            background-color: var(--color-primary-background-hovered);
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
    }
}

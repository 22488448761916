.field-title {
    padding: 0 0 12px 14px;
    color: var(--color-grey-placeholder);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    &_dense {
        padding: 0;
    }
}

.player {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;

    --currentTime: 0;

    &__controls {
        padding: 28px 32px 8px;
    }

    &__badge-controls {
        position: absolute;
        top: -32px;
        left: -16px;
        display: flex;
        width: calc(100% + 32px);
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__track {
        position: relative;
        height: 39px;
        flex: 1 1 auto;
    }

    &__oscillogram {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__channel-tracks {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    &__channel-track {
        position: relative;
        height: 19px;
    }

    &__track-line {
        position: absolute;
        top: 19px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--color-primary-hovered);
        pointer-events: none;
    }

    &__track-labels {
        position: relative;
        display: flex;
        height: 39px;
        flex-direction: column;
        justify-content: space-between;
    }

    &__track-label {
        display: flex;
        width: 80px;
        height: 19px;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 16px;
        font-size: 14px;
    }

    &__seek {
        position: absolute;
        z-index: 1;
        top: 0;
        left: calc(var(--currentTime) * 100%);
        width: 4px;
        height: 40px;
        border-radius: 2px;
        background: var(--color-primary);
        pointer-events: none;
        transition: left 300ms;
    }

    &__timer {
        color: var(--color-grey-text);
        font-size: 12px;
        white-space: nowrap;
    }

    &__marks {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__mark-group {
        position: absolute;
        z-index: 2;
        width: 16px;
        height: 16px;
        cursor: pointer;
        transform: translateX(-8px);
    }

    &__mark {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 50%;
        margin: 0 14px 0 0;
        background-color: var(--color-grey-placeholder);

        &:focus {
            outline: none;
        }
    }

    &__noise {
        position: absolute;
        z-index: 2;
        top: calc(100% + 4px);
        display: flex;
        border: 1px solid #2f80ed;
        border-radius: 50%;
        background-color: var(--color-white);
        color: #2f80ed;

        &_operator {
            top: unset;
            bottom: calc(100% + 4px);
        }

        &:hover {
            background-color: #2f80ed;
            color: var(--color-white);
        }
    }

    &__noise-tag {
        width: 14px;
        height: 14px;
    }

    &__hold {
        position: absolute;
        z-index: 3;
        top: 0;
        height: 100%;
        border: none;
        background: var(--color-purple-opacity);

        &_excluded {
            width: 2px;
            margin-left: -1px;
        }

        &:hover {
            filter: brightness(0.5);
        }
    }

    &__hold-title {
        position: absolute;
        bottom: -30px;
        display: none;
        margin: auto;
        font-size: 10px;
        white-space: nowrap;
    }

    &__hold:hover &__hold-title,
    &__hold-title_visible {
        display: flex;

        &::before {
            position: absolute;
            bottom: 15px;
            left: 50%;
            width: 1px;
            height: 14px;
            background: var(--color-grey-icon);
            content: '';
        }
    }

    &__interruption {
        position: absolute;
        top: 0;
        height: 100%;
        border: none;
        background: var(--color-assertive-background-hovered);
    }

    &__silence {
        position: absolute;
        z-index: 2;
        top: 0;
        height: 100%;
        border: none;
        background: var(--color-grey-placeholder-opacity);

        &:hover {
            filter: brightness(0.5);
        }
    }

    &__silence-duration {
        position: absolute;
        bottom: -30px;
        display: none;
        margin: auto;
        font-size: 10px;
    }

    &__silence:hover &__silence-duration {
        display: flex;

        &::before {
            position: absolute;
            bottom: 15px;
            left: 50%;
            display: block;
            width: 1px;
            height: 14px;
            background: var(--color-grey-icon);
            content: '';
        }
    }

    &__dialogs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__dialog {
        position: absolute;
        top: 0;
        height: 100%;
        border: 0 solid green;
        border-radius: 3px;
        background-color: #0f02;
        pointer-events: none;

        &_empty {
            border: 0 solid red;
            background-color: #f002;
        }

        &_manual {
            border-width: 1px;
        }
    }
}

.player-settings {
    padding: 16px;

    &_badge-control {
        padding-top: 24px;
        padding-bottom: 0;
    }

    &__control {
        position: relative;
        width: 16px;
        height: 16px;
        margin: auto;
        color: var(--color-grey-icon);
        font-size: 14px;
        text-align: center;

        /* TODO: не смог победить - какое-то странное правило */
        /* stylelint-disable-next-line no-descending-specificity */
        svg {
            width: 100%;
            height: 100%;
            color: var(--color-grey-icon);
        }

        &:hover {
            color: $primaryColor;

            svg {
                color: $primaryColor;
            }
        }

        &_custom {
            width: 32px;
            height: 16px;
        }
    }

    &__speed {
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-primary);
        border-radius: 2px;
        text-align: center;

        &_active {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    &__header {
        padding-top: 15px;
        padding-bottom: 32px;
    }

    &__title {
        font-size: 1.125rem;
        font-weight: 700;
        text-align: center;

        &_left {
            text-align: left;
        }
    }

    &__slider-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    &__slider-container {
        padding: 8px 0;
    }

    &__slider {
        width: 100%;
    }
}

.player-container {
    position: relative;
    border-top: 1px solid var(--color-grey-border);
    border-bottom: 1px solid var(--color-grey-border);

    &:focus-within,
    &:focus-visible {
        background-color: var(--color-primary-background-light);
        outline: unset;
    }

    &_error {
        height: 100px;
    }

    &__title {
        padding: 12px 32px;
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

.mark-group-count {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    background-color: var(--color-white);
    color: $primaryColor;
    font-size: 12px;

    &:hover {
        background-color: $primaryColor;
        color: var(--color-white);
    }
}

.player__channel-tracks .player__channel-track {
    &:nth-child(1) .player__mark-group {
        bottom: calc(100% + 4px);
    }

    &:nth-child(2) .player__mark-group {
        top: calc(100% + 4px);
    }
}

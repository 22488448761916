.widget {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 8 * $grid-size 0 0 8 * $grid-size;

    &__content {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        flex-direction: column;
        border-radius: 4px;
        background-color: var(--color-white);

        &_with-title {
            transform: scale(1);

            & .chart {
                margin-top: $grid-size * 8;
            }

            & .am5exporting.am5exporting-menu {
                position: fixed;
                top: 0;
            }

            & .chart__fake_sticky {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        position: absolute;
        width: calc(100% - $grid-size * 9);
        height: 8 * $grid-size;
        padding: 3 * $grid-size 4 * $grid-size;
        background-color: white;

        &:empty {
            display: none;
        }
    }
}

.billing {
    &__collection {
        // width: 210 * $grid-size;
        padding-bottom: 12 * $grid-size;
    }

    &__metric-chart {
        overflow: hidden;
        width: 60 * $grid-size;
        height: 24 * $grid-size;
        border-radius: 4px;

        & .chart-metric {
            padding: 4 * $grid-size 3 * $grid-size;
        }
    }

    &__column-chart {
        width: 100%;
        height: 100 * $grid-size;
    }

    &__totals-column {
        width: 60 * $grid-size;
    }

    &__filter-input {
        width: 280px;
    }

    &__period-item {
        &_active {
            color: var(--color-primary);
        }
    }
}

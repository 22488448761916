.document-table {
    & &_sized {
        padding: 0;
    }

    th {
        vertical-align: middle;
    }

    th:nth-child(1) {
        text-align: right;
    }

    td {
        max-width: 400px;
        vertical-align: middle;
    }

    & &__open-button {
        display: block;
        width: 20px;
        height: 20px;

        svg {
            width: 100%;
            height: 100%;
            color: var(--color-grey-icon);
        }

        &:hover {
            svg {
                color: $primaryColor;
            }
        }
    }

    & &__cell {
        height: 46px;
        min-height: 46px;

        &_sized {
            padding: 0;
        }

        &_custom {
            padding: 0;
        }

        &_actions {
            width: 64px;
            min-width: 64px;
            max-width: 64px;
            padding: 0 8px 0 16px;
        }

        &_select {
            width: 32px;
            min-width: 32px;
            max-width: 32px;
            padding: 0 8px;
        }

        &_open {
            width: 36px;
            min-width: 36px;
            max-width: 36px;
            padding: 0 8px;
        }

        &_agent-quality {
            max-width: unset;
        }

        &_player {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            padding: 0 4px;
            text-align: center;

            .document-table__player {
                display: none;
                width: 32px;
                height: 32px;

                svg {
                    width: 100%;
                    height: 100%;
                    color: $primaryColor;
                }

                &:hover {
                    svg {
                        color: $primaryDarkColor;
                    }
                }
            }

            .document-table__index {
                display: block;
            }

            &:hover {
                .document-table__player {
                    display: block;
                }

                .document-table__index {
                    display: none;
                }
            }
        }

        &:hover .document-table__column-settings {
            opacity: 1;
        }

        &:hover .document-table__icon {
            visibility: visible;
        }
    }
}

.document-table-settings-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

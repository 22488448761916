.input-number {
    position: relative;

    &__input {
        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    &__buttons {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
    }

    &__button {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        color: var(--color-grey-icon);

        svg {
            width: 14px;
            height: 14px;
        }

        &:disabled {
            color: var(--color-grey-disabled);
            cursor: default;
        }

        &:hover:not(:disabled) {
            color: $primaryColor;
        }

        &:first-child svg {
            align-self: flex-end;
        }
    }
}

.statistics__action-input.input-number .input-number__buttons {
    width: 14px;
}

.statistics__action-input.input-number .input-number__button svg {
    width: 9px;
    height: 9px;
}

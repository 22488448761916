.tree-item-subjects {
    overflow: hidden;
    max-width: 100%;
    line-height: normal;

    &__subject {
        max-width: $grid-size * 30;
    }

    &__number {
        line-height: normal;
    }
}

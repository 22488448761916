.sidebar-navigation {
    &__title {
        padding: 16px 40px;
        font-size: 24px;
        font-weight: 700;
    }

    &__menu {
        padding: 8px 0;
    }

    & &__menu-item {
        display: block;
        padding: 12px 40px;
        font-size: 16px;

        &_active {
            background: var(--color-primary-background);
            color: var(--color-primary);
        }

        &:hover {
            background: var(--color-primary-background);
            color: var(--color-primary);
        }
    }
}

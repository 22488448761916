.reorderable {
    &__list {
        display: flex;
        max-height: 500px;
        flex-direction: column;
        border-radius: 6px;
        margin-top: 10px;
        background-color: var(--color-grey-background);
    }

    &__list-item:hover {
        background-color: var(--color-white);
        cursor: pointer;
    }
}

.speaker-notifications {
    &__table thead tr th {
        width: 100px;
        max-width: 100px;
    }

    &__status {
        width: 16px;
        height: 16px;
        color: var(--color-green-main);
        cursor: pointer;

        &_disabled {
            color: var(--color-assertive-active);
        }
    }
}

.speaker-manager {
    &__table {
        tbody tr td {
            padding: 4px 16px;
            vertical-align: middle;
        }

        thead tr th,
        tbody tr td {
            &:nth-child(1) {
                width: 70px;
                max-width: 70px;
                padding-left: 40px;
            }

            &:nth-child(2) {
                width: 70px;
                max-width: 70px;
            }

            &:nth-child(3) {
                width: unset;
                min-width: 0;
                max-width: 300px;
            }

            &:nth-child(4) {
                width: 150px;
                max-width: 150px;
            }

            &:nth-child(8) {
                width: 105px;
                max-width: 105px;
            }

            &:nth-child(9) {
                width: 190px;
                max-width: 190px;
            }

            &:nth-child(10) {
                width: 120px;
                max-width: 120px;
            }

            &:nth-child(n + 5):nth-child(-n + 7) {
                width: 200px;
                max-width: 200px;
            }
        }

        &_marker-cell {
            position: relative;
        }
    }

    &__color-mark {
        position: absolute;
        left: 15px;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &_active {
            border: 1px solid var(--color-green-main);
            background: var(--color-green-hover);
        }

        &_inactive {
            border: 1px solid var(--color-black);
            background: var(--color-grey-placeholder);
        }

        &_warn {
            border: 1px solid var(--color-assertive-active);
            background: var(--color-assertive);
        }
    }

    &__training {
        width: 16px;
        height: 16px;
        cursor: pointer;

        &_big {
            width: 25px;
            height: 25px;
        }

        &_success {
            color: var(--color-green-main);
        }

        &_error {
            color: var(--color-orange);
        }
    }
}

.speaker-avatar {
    position: relative;
    width: 140px;
    height: 160px;
    padding: 0;
    background: transparent;
    color: var(--color-grey-icon);
    cursor: pointer;

    &__delete {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        background-color: var(--color-grey-overlay);
        opacity: 0;
        transition: 0.2s;
    }

    &_small {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &_empty {
            background-color: var(--color-white);
        }
    }

    &__label {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 6px;
        background: var(--color-grey-overlay);
        color: var(--color-white);
        opacity: 0;
        text-align: center;
        transition: 0.2s;
    }

    &_border {
        border: 1px solid var(--color-grey-disabled);
    }

    &:hover {
        .speaker-avatar__label {
            opacity: 1;
        }

        .speaker-avatar__delete {
            opacity: 1;
        }
    }
}

.secondary-settings {
    &__button-text {
        &_overflow {
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.projects-table {
    tr {
        th,
        td {
            width: 180px;
            min-width: 180px;
            max-width: 180px;
            line-height: 1rem;
            vertical-align: middle;
            white-space: nowrap;

            &:nth-child(2) {
                width: unset;
                min-width: 300px;
                max-width: 0;
                padding-left: 0;
                text-align: left;
            }

            &:first-child {
                min-width: 40px;
                max-width: 40px;
                padding: 0;
                text-align: center;

                &:not(:empty) {
                    cursor: pointer;
                }
            }
        }
    }

    & &__cell {
        &_collection {
            font-weight: 700;
        }

        &_clear {
            padding: 0;
        }
    }
}

.projects-status-button {
    display: block;
    margin: auto;
    color: var(--color-grey-icon);
    text-align: center;

    &:disabled {
        color: var(--color-grey-disabled);
    }

    &:hover:not(:disabled) {
        color: var(--color-primary);
    }
}

.projects-status-icon {
    display: block;
    width: 16px;
    height: 16px;
}

$headerHeight: 40px;
$pagePanelWidth: calc($grid-size * 112);

.page {
    position: relative;
    display: flex;
    height: 100vh;
    flex-direction: column;
    padding-top: $headerHeight;
    background-color: var(--color-white);
    font-size: 0.875rem;

    &_clear {
        padding-top: unset;
        background-color: unset;
    }
}

.page-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: $headerHeight;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border-color: var(--color-grey-border);
    background: var(--color-primary);
    color: var(--color-white);

    &__project-selector {
        margin-left: 32px;
    }
}

.page-body {
    position: relative;
    display: flex;
    overflow: auto;
    height: 100%;
    flex: 1 1 auto;
    flex-wrap: nowrap;

    &__overlay {
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.page-title {
    color: var(--color-grey-text);
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
}

.page-section {
    &_stacked {
        margin-top: 32px;
    }

    &__title {
        color: var(--color-grey-text);
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
    }
}

.page-navigation {
    padding: 0;

    &__separator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--color-grey-border);
    }
}

.page-panel {
    display: flex;
    overflow: auto;
    height: 100%;
    flex-direction: column;
    background-color: var(--color-white);

    &_left {
        // TODO: нужно проработать размер, чтоб стакалось с хедером красиво
        width: $pagePanelWidth;
        flex-shrink: 0;
        border-right: 1px solid var(--color-grey-border);
    }

    &_right {
        width: $pagePanelWidth;
        flex-shrink: 0;
        border-left: 1px solid var(--color-grey-border);
    }

    &_narrow {
        width: 300px;
    }

    &_wide {
        width: calc($grid-size * 211);
    }

    &_grow {
        flex: 1;
    }

    &_main {
        flex: 1 1 auto;
    }

    &__content {
        padding: calc($grid-size * 4) calc($grid-size * 10);

        &_z-10 {
            z-index: 10;
        }

        &_main-panel {
            padding: 40px;
        }

        &_sticky {
            position: sticky;
            z-index: 10;
            top: 0;
            background-color: var(--color-white);
        }

        &_stacked {
            padding-top: 0;
            padding-bottom: 0;
        }

        &_chart {
            width: 100%;
            height: 100%;
        }

        &_clear {
            padding: 0;
        }

        &_clear-top {
            padding-top: 0;
        }

        &_clear-left {
            padding-left: 0;
        }

        &_clear-right {
            padding-right: 0;
        }

        &_high-top {
            padding-top: $grid-size * 6;
        }

        &_narrow-right {
            padding-right: $grid-size * 4;
        }

        &_clear-bottom {
            padding-bottom: 0;
        }

        &_main {
            flex: 1 1 auto;
        }

        &_noscroll {
            overflow: hidden;
        }

        &_scroll {
            overflow: auto;
        }

        &_full-height {
            height: 100%;
        }

        &_hide-empty {
            &:empty {
                display: none;
            }
        }
    }
}

.page-sidebar {
    &__title {
        padding: 16px 40px;
        font-size: 24px;
        font-weight: 700;
    }

    &__content {
        padding: 8px 0;
    }

    &__filter {
        width: 100%;
        padding: 0 40px;
    }
}

.page-footer {
    z-index: 1;
    padding: 0.5rem 2.5rem;
    background: var(--color-white);
    box-shadow: 0 -2px 10px rgba(224 224 224 / 40%);

    &_no-shadow {
        box-shadow: none;
    }
}

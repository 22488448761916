.table-head-sort {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    &_turned {
        max-height: 200px;
        flex-direction: column;
    }

    &_end {
        justify-content: end;
    }

    &_start {
        justify-content: start;
    }

    &__icon-container {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    &__2-icon-container {
        display: flex;
        width: fit-content;
        height: 14px;
        flex-direction: column;
        cursor: pointer;
        row-gap: 6px;
    }

    &__label {
        @include text-overflow;

        color: var(--color-grey-text);
        font-weight: 700;
    }

    &__label:disabled {
        cursor: default;
    }

    &_turned &__label {
        transform: scale(-1);
        writing-mode: vertical-lr;
    }

    &__icon {
        width: 14px;
        height: 14px;
        color: var(--color-grey-icon);
        transform: scale(1.6);
        visibility: hidden;

        &_visible {
            visibility: visible;
        }
    }

    &__condition-text {
        line-height: 1.5rem;
    }

    &:hover &__icon {
        visibility: visible;
    }

    &__2-icon {
        width: 8px;
        height: 8px;
        color: var(--color-grey-icon);

        &_active {
            color: var(--color-primary);
        }
    }
}

.notification {
    &__content {
        grid-template-columns: 800px 1fr;
    }

    &__table {
        table-layout: fixed;

        th {
            &:nth-child(3) {
                width: 250px;
            }

            &:last-child {
                width: 200px;
            }
        }

        td {
            vertical-align: middle;

            &:nth-child(1) {
                text-align: left;
            }
        }
    }

    tbody {
        .notification__row {
            &_new {
                background-color: var(--color-green-background-light);

                &:nth-child(odd) {
                    background-color: var(--color-green-background);
                }

                &:hover {
                    background-color: var(--color-green-hover-light);
                }
            }
        }
    }

    &__icon-type {
        color: var(--color-grey-icon);
    }
}

.column-settings {
    &_grid {
        grid-template-columns: 480px 2fr;
    }

    &__align-icon {
        padding: 5px;
        border-radius: 4px;
        color: var(--color-grey-icon);
        font-size: 20px;

        &_selected {
            color: var(--color-primary);
        }
    }

    &__header {
        &__field-name {
            width: 100%;
            padding: 10px;
            border-top: solid 1px var(--color-grey-border);
            border-bottom: solid 1px var(--color-grey-border);
            font-weight: bold;
        }
    }

    &__lorem-items {
        & :nth-child(odd) {
            background-color: var(--color-grey-background);
        }
    }

    &__lorem-item {
        overflow: hidden;
        margin: 5px;
        line-height: 1.6em;
    }

    &__settings-panel {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__content {
        width: 240px;
    }

    &__input {
        min-width: 160px;
    }
}

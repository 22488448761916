.speaker-profile {
    &__header {
        &_download-button {
            height: 40px;
        }
    }

    &__table {
        tbody tr td {
            vertical-align: middle;
        }

        thead tr th,
        tbody tr td {
            &:nth-child(1) {
                width: 5px;
                max-width: 5px;
            }

            &:nth-child(2) {
                width: unset;
                min-width: 0;
                max-width: 255px;
            }

            &:last-child {
                width: 50px;
                max-width: 50px;
                padding: 0;
            }

            &:nth-child(n + 3):nth-child(-n + 6) {
                width: 155px;
                max-width: 155px;
            }
        }
    }

    &__left-panel {
        width: 488px;
    }
}

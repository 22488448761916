.statistics {
    --level: 0;

    color: var(--color-grey-text);
    font-size: 0.875rem;

    &__row {
        position: relative;
        display: flex;
        width: 100%;
        height: 32px;
        align-items: center;
        padding-right: 16px;
        padding-left: calc((var(--level)) * 32px + 16px);

        &:hover {
            background: var(--color-primary-background);
        }

        &_active {
            background: var(--color-primary-background);
        }

        &_with-icon {
            gap: $grid-size * 4;
        }
    }

    &__title {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        align-self: stretch;
    }

    &__node &__title {
        text-transform: uppercase;
    }

    &__node &__node &__title {
        font-weight: 700;
        text-transform: unset;

        &_category {
            font-weight: 400;
        }
    }

    &__switcher {
        display: flex;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        align-items: center;
        color: var(--color-grey-icon);

        &:disabled {
            color: var(--color-grey-disabled);
        }
    }

    &__switcher-icon {
        width: 24px;
        height: 24px;

        &_disabled {
            color: var(--color-grey-disabled);
        }
    }

    &__dot-marker {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 50%;
        margin: 0 14px 0 6px;
        background-color: var(--color-grey-placeholder);
    }

    &__manual-marker {
        padding: 0 4px;
    }

    &__counter {
        width: 80px;
        flex-shrink: 0;
        text-align: right;

        &_small {
            width: 40px;
        }
    }

    &__actions {
        display: flex;
        flex-shrink: 0;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 4px;

        &_catalog,
        &_status {
            width: 68px;
            gap: 12px;
        }

        &_catalog-edit {
            width: 116px;
            gap: 12px;
        }

        &_catalog-save {
            width: 68px;
            gap: 12px;
        }

        &_status-small {
            width: 32px;
        }
    }

    &__action-button {
        width: 20px;
        height: 20px;
    }

    &__action-input {
        width: 44px;
        max-width: 44px;
    }

    &__action-icon {
        width: 20px;
        height: 20px;
        color: var(--color-grey-icon);

        &_add {
            color: var(--color-green-main);
        }

        &_delete {
            color: var(--color-assertive);
        }

        &_na {
            color: var(--color-primary);
        }

        &_revert {
            color: yellow;
        }
    }

    &_action-difference-state {
        color: var(--color-green-main);

        &_different {
            color: var(--color-assertive);
        }
    }

    &__calibration-hint {
        height: 32px;
        background-color: var(--color-grey-background);
    }
}

.statistics-panel {
    transition: width 0.2s;

    &_hidden {
        width: 0;
    }

    &__collapse-button {
        margin: 12px 16px;
    }

    &__toggle-button {
        position: absolute;
        z-index: 1;
        bottom: 30%;
        width: 24px;
        height: 32px;
        border-radius: 1px;
        background: var(--color-primary-background-hovered);
        color: var(--color-white);
        cursor: pointer;
        transform: translateX(-100%);

        &_left {
            transform: translateX(-1px);
        }

        &:hover {
            filter: brightness(90%);
        }
    }

    &__toggle-button-icon {
        width: 16px;
        height: 16px;
    }

    &_hidden &__toggle-button {
        width: 20px;
        transform: translateX(-100%);
    }

    &_hidden &__toggle-button_left {
        width: 16px;
        transform: translateX(0);
    }

    &__navigation {
        padding: 12px 16px;
        gap: 40px;
    }

    &__navigation-button {
        &:hover {
            color: $primaryDarkColor;
        }

        &_active {
            color: var(--color-primary);
        }
    }

    &__task-controles {
        padding: 12px 16px;
    }
}

.statistics-header {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    background-color: var(--color-white);
}

.statistics-filter {
    width: 100%;

    &__input {
        width: 100%;
    }
}

.node-tree {
    &__folder {
        padding: 3px 120px 3px 50px;

        &_root {
            padding: 6px 120px 6px 50px;
            color: var(--color-grey-placeholder);
            text-transform: uppercase;
        }
    }

    &__category {
        padding: 0 48px 0 50px;
        color: var(--color-grey-text);

        &:hover {
            text-decoration: none;
        }
    }

    &__action {
        right: 60px;
    }
}

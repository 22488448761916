:root {
    /* COLORS */
    --color-black: black;
    --color-white: white;
    --color-primary: #2f80ed;
    --color-primary-dark: #2b76db;
    --color-primary-darkest: #2970cf;
    --color-primary-hovered: #76a4e1;
    --color-primary-focus: #4e97fa;
    --color-primary-active-light: #1666d2;
    --color-primary-active: #1666d2;
    --color-primary-background: #dfedff;
    --color-primary-background-hovered: #b0cff8;
    --color-primary-background-hovered-dark: #a1c9ff;
    --color-primary-background-hovered-light: #edf3fc;
    --color-primary-background-light: #f4f9ff;
    --color-assertive: #eb5757;
    --color-assertive-hovered: #ed7171;
    --color-assertive-focus: #e34848;
    --color-assertive-active: #dc2b2b;
    --color-assertive-disabled: #f9cdcd;
    --color-assertive-background: #fee;
    --color-assertive-background-hovered: #fcd4d4;
    --color-gold-main: #f2c94c;
    --color-gold-hover: #fde257;
    --color-gold-hover-light: #ffee9b;
    --color-gold-background: #fff7e2;
    --color-green-highlight: #6cc3a4;
    --color-green-main: #27ae60;
    --color-green-hover: #75d29d;
    --color-green-hover-light: #a3d9c9;
    --color-green-background: #d4f2e9;
    --color-green-background-light: #e0fdf4;
    --color-grey-disabled: #cbcbcb;
    --color-grey-icon: #9299a2;
    --color-grey-background: #f5f6f8;
    --color-grey-text: #4f4f4f;
    --color-grey-title: #666;
    --color-grey-border: #e0e0e0;
    --color-grey-placeholder: #959595;
    --color-grey-placeholder-opacity: #95959525;
    --color-grey-overlay: #0004;
    --color-grey-overlay-light: #0002;
    --color-orange: #ffa500;
    --color-purple: #a25bb4;
    --color-purple-opacity: #a25bb425;

    /* FONTS */
    --default-font-size: 16px;
    --default-font: 'Roboto', sans-serif;
}

$grid-size: 4px;
$scroll-size-width: 4px;
$scroll-size-height: 6px;

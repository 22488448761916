.condition {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: var(--color-green-background);
    color: var(--color-green-main);
    font-size: 0.75rem;
    line-height: 1.5rem;

    &_negated {
        background-color: var(--color-assertive-disabled);
        color: var(--color-assertive);
    }

    &__icon {
        font-size: 0.75rem;
    }

    &__control {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;

        &:hover {
            background: var(--color-grey-overlay-light);
        }
    }

    &__remove &__icon {
        display: none;
    }

    &:hover &__remove &__icon {
        display: block;
    }

    &__label {
        padding: 0 4px;
    }
}

.condition-title {
    color: var(--color-grey-text);
    font-weight: 700;
}

.condition-actions {
    height: 1.5rem;
}

.condition-group-element {
    position: relative;

    &__remove {
        position: absolute;
        top: 50%;
        right: -20px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
    }
}

.condition-control {
    position: relative;

    &__add {
        position: absolute;
        right: 0;
        bottom: -20px;
        width: 16px;
        height: 16px;
    }
}

.chart-qos-table {
    overflow: auto;

    & th {
        vertical-align: bottom;
    }

    & tr {
        &:hover {
            background-color: var(--color-grey-border);
            cursor: pointer;
        }

        & td {
            border-collapse: collapse;

            &:hover {
                box-shadow: inset 0 0 2px var(--color-black);
            }

            &:nth-child(1) {
                border-right: 3px solid var(--color-white);
                text-align: right;
            }

            &:nth-child(2) {
                border-right: 3px solid var(--color-white);
            }
        }

        &:hover > td {
            filter: brightness(1.2);
        }
    }

    &__group {
        border-right: 3px solid var(--color-white);
        border-left: 3px solid var(--color-white);
    }

    &__average {
        border-bottom: 3px solid var(--color-white);
    }

    &__head-group {
        & .table-head-sort__label {
            font-weight: 400;
        }
    }
}

.private-label {
    display: block;
    width: 16px;
    height: 16px;
    color: var(--color-grey-icon);

    &_favorite {
        color: var(--color-gold-main);
    }

    &_seen {
        color: var(--color-green-main);
    }
}

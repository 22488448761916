.markdown {
    font-size: var(--size);

    b {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    h1 {
        height: calc(var(--size) * 2.5);
        font-size: calc(var(--size) * 2.5);
        font-weight: bold;
        line-height: calc(var(--size) * 2.5);
    }

    h2 {
        height: calc(var(--size) * 2);
        font-size: calc(var(--size) * 2);
        font-weight: bold;
        line-height: calc(var(--size) * 2);
    }

    h3 {
        height: calc(var(--size) * 1.75);
        font-size: calc(var(--size) * 1.75);
        font-weight: bold;
        line-height: calc(var(--size) * 1.75);
    }

    h4 {
        height: calc(var(--size) * 1.5);
        font-size: calc(var(--size) * 1.5);
        font-weight: bold;
        line-height: calc(var(--size) * 1.5);
    }

    h5 {
        height: calc(var(--size) * 1.25);
        color: inherit;
        font-size: calc(var(--size) * 1.25);
        font-weight: bold;
        line-height: calc(var(--size) * 1.25);
    }

    h6 {
        font-weight: bold;
    }
}

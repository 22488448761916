.link {
    color: $primaryColor;
    cursor: pointer;
    font-size: $fontSize;
    line-height: 1;

    &_inherit {
        font-size: inherit;
        line-height: inherit;
    }

    &:hover {
        color: $primaryDarkColor;
    }

    &:active {
        color: $primaryDarkerColor;
    }

    &:visited {
        color: var(--color-purple);
    }

    &:disabled {
        color: var(--color-grey-disabled);
        pointer-events: none;
    }

    &_primary {
        color: var(--color-grey-text);
    }

    &_icon {
        color: var(--color-grey-icon);
    }

    &_disabled,
    &_disabled:visited {
        color: var(--color-grey-disabled);
        pointer-events: none;
    }

    &_button-block {
        padding: 0.625rem 0;
        line-height: 1.125rem;
    }

    &_button-tall {
        line-height: 1.125rem;
    }

    &_button {
        &:visited {
            color: $primaryColor;

            &:hover {
                color: $primaryDarkColor;
            }

            &:active {
                color: $primaryDarkerColor;
            }
        }
    }

    &_assertive,
    &_assertive#{&}_button {
        color: var(--color-assertive);

        &:hover {
            color: var(--color-assertive);
        }

        &:active {
            color: var(--color-assertive);
        }

        &:disabled {
            color: var(--color-grey-disabled);
            pointer-events: none;
        }
    }
}

.reports-table {
    table-layout: fixed;

    thead tr th,
    tbody tr td {
        @include text-overflow;

        text-align: left;

        &:last-child {
            width: $grid-size * 20;
            max-width: $grid-size * 20;
        }
    }
}

.feedback {
    & > input:first-child {
        position: absolute;
        left: -200%;
    }

    &__textarea {
        min-height: 232px;
        resize: vertical;
    }

    &__tag {
        width: 150px;

        &_invalid {
            background-color: var(--color-assertive);
            color: var(--color-white);
        }
    }

    &__img {
        width: 150px;
        height: 150px;
        object-fit: scale-down;
    }
}

.agent-quality {
    gap: 5px;

    &__value {
        position: relative;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border: none;
        border-radius: 2px;
        background-color: var(--color-primary-background);
        color: var(--color-primary);
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;

        &_small {
            height: 24px;
            font-size: 14px;
            line-height: 25px;
        }

        &_modified {
            position: absolute;
            right: 2px;
            bottom: 2px;
            width: 11px;
            height: 11px;
        }
    }
}

.agent-quality-details {
    display: flex;
    gap: 1px;

    &__detail {
        position: relative;
        width: 48px;
        height: 24px;
        border-radius: 2px;
        background-color: var(--color-primary-background);
        color: var(--color-primary);
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;

        &_modified {
            position: absolute;
            right: 2px;
            bottom: 2px;
            width: 8px;
            height: 8px;
        }

        // TODO: рефачить стили, тут в целом и дублирование и БЭМ не очень
        &_modified-small {
            position: absolute;
            right: 2px;
            bottom: 2px;
            width: 5px;
            height: 5px;
        }

        &_na {
            font-size: 10px;
            font-weight: 700;
        }

        &_small {
            width: 24px;
        }
    }

    &__items-panel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
    }
}

.agent-quality-items {
    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        background-color: transparent;
        gap: 32px;

        &::before {
            position: absolute;
            top: 12px;
            bottom: 12px;
            left: 19px;
            width: 1px;
            background-color: var(--color-grey-border);
            content: '';
        }

        &_dialog {
            &::before {
                top: -25px;
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        gap: 80px;

        &::before {
            position: absolute;
            top: 12px;
            left: -20px;
            width: 18px;
            height: 1px;
            background-color: var(--color-grey-border);
            content: '';
        }

        &_title {
            @include text-overflow;

            text-align: left;
        }
    }

    &__weight {
        position: absolute;
        z-index: 1;
        top: 12px;
        left: -20px;
        width: 20px;
        font-size: 12px;
        text-align: center;

        &_title {
            left: -15px;
        }
    }
}

.agent-quality-dialog {
    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__item {
        position: relative;

        &_list {
            margin-left: 5px;
        }

        &::before {
            position: absolute;
            top: 12px;
            bottom: -45px;
            left: -20px;
            width: 1px;
            background-color: var(--color-grey-border);
            content: '';
        }

        &:last-child {
            &::before {
                display: none;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        margin-left: 10px;
        gap: 40px;

        &::before {
            position: absolute;
            top: 12px;
            left: -20px;
            width: 27px;
            height: 1px;
            background-color: var(--color-grey-border);
            content: '';
        }
    }
}

.popups {
    position: fixed;
    z-index: 10000000;
    right: 10px;
    bottom: 10px;
    display: flex;
    max-height: 100vh;
    flex-direction: column-reverse;
    row-gap: 10px;
}

.popup {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 450px;
    min-height: 88px;
    flex-shrink: 0;
    align-items: center;
    padding-left: 6px;
    border-radius: 6px;
    animation: slide 500ms ease 0s 1 forwards;
    background-color: var(--color-white);
    box-shadow: 0 0 8px var(--color-grey-placeholder);
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.2;
    text-align: left;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 6px;
        height: 100%;
        background: gray;
        content: '';
    }

    &__body {
        overflow: hidden;
        flex: 1 1 auto;
        padding: 20px;
        font-size: 14px;

        &:hover {
            background: var(--color-grey-background);
        }
    }

    &__title {
        color: var(--color-grey-text);
        font-weight: bold;
        line-height: 20px;
    }

    &__message {
        overflow: hidden;
        color: var(--color-grey-placeholder);
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &_multiline {
            white-space: pre-wrap;
        }
    }

    &_expanded {
        cursor: default;

        .popup__body {
            padding: 20px 0 20px 20px;

            &:hover {
                background: var(--color-white);
            }
        }

        .popup__message {
            max-height: 300px;
            padding-right: 20px;
            overflow-y: auto;
            text-overflow: unset;
            white-space: unset;
        }
    }

    &_error {
        &::before {
            background: red;
        }
    }

    &_message {
        &::before {
            background: green;
        }
    }

    &__close {
        display: flex;
        width: 50px;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-left: 1px solid var(--color-grey-border);
        color: var(--color-grey-placeholder);
        cursor: pointer;
        font-size: 18px;

        &:hover {
            background: var(--color-grey-background);
            color: var(--color-primary);
        }
    }

    @keyframes slide {
        from {
            transform: translateY(calc(100% + 10px));
        }

        to {
            transform: translateY(0);
        }
    }
}

.text {
    &_overflow {
        @include text-overflow;
    }

    &_empty {
        &:empty {
            display: none;
        }
    }

    // TODO перенести в text-overflow с учетом проверки всего решения
    &_cropped {
        max-width: 100%;
    }

    &_overflow-multiline {
        position: relative;
        overflow: hidden;
        line-height: 16px;
        white-space: normal;
    }

    &_wrap {
        white-space: pre-wrap;
    }

    &_transform-reset {
        text-transform: none;
    }

    &_upper {
        text-transform: uppercase;
    }

    &_disable {
        color: $shade500;
        user-select: none;
    }

    &_center {
        text-align: center;
    }

    &_left {
        text-align: left;
    }

    &_right {
        text-align: right;
    }

    &_nowrap {
        white-space: nowrap;
    }

    &_normal {
        font-weight: normal;
    }

    &_bold {
        font-weight: bold;
    }

    &_weight-500 {
        font-weight: 500;
    }

    &_weight-700 {
        font-weight: 700;
    }

    // TODO: отрефакторить через rem
    &_size-12 {
        font-size: 12px;
    }

    &_size-16 {
        font-size: 16px;
    }

    &_size-40 {
        font-size: 40px;
        line-height: 48px;
    }

    &_height-24 {
        line-height: 24px;
    }

    &_small {
        font-size: 13px;
    }

    &_title {
        color: var(--color-grey-placeholder);
        font-size: 18px;
        font-weight: 700;
    }

    &_word-break {
        word-break: break-word;
    }

    &_assertive {
        color: var(--color-assertive);
    }

    &_positive {
        color: var(--color-green-main);
    }

    &_grey-default {
        color: var(--color-grey-text);
    }

    &_grey-light {
        color: var(--color-grey-placeholder);
    }

    &_white {
        color: var(--color-white);
    }

    &_issue {
        color: var(--color-orange);
    }

    &_primary {
        color: var(--color-grey-text);
    }

    &_grey-very-light {
        color: var(--color-grey-border);
    }

    &#{&}_half-size {
        font-size: 0.5rem;
    }
}

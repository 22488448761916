.color-picker {
    position: relative;
    width: $grid-size * 10;
    height: $grid-size * 10;
    flex-shrink: 0;

    &_small {
        width: $grid-size * 4;
        height: $grid-size * 4;
    }

    &__preview {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
            linear-gradient(-45deg, #808080 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #808080 75%),
            linear-gradient(-45deg, transparent 75%, #808080 75%);
        background-position: -1px -1px, -1px 3px, 3px -5px, -5px -1px;
        background-size: 8px 8px;
    }

    &__palette {
        padding: 0;
        margin: 2px 0 0;
        background-color: var(--color-grey-text);

        &::before,
        &::after {
            display: none;
        }
    }

    &__input-text {
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: var(--color-white);
    }
}

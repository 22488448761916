.accounts-table {
    table-layout: fixed;

    thead tr th,
    tbody tr td {
        @include text-overflow;

        text-align: left;

        & > span {
            justify-content: flex-start;
        }

        &:nth-last-child(1) {
            padding: unset;
            text-align: center;
            vertical-align: middle;
        }
    }

    thead tr th:nth-last-child(1) {
        width: 40px;
    }
}

.list {
    width: 100%;
    min-width: 0;

    &__reorderable-item {
        cursor: grab;
    }

    &__item {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        padding: 0.625rem;
        border: 1px solid transparent;
        border-radius: 2px;
        font-size: 0.875rem;
        line-height: 1.125rem;

        &_draggable {
            cursor: pointer;
        }

        &_unselectable {
            cursor: default;
            user-select: none;
        }
    }

    &__item-button {
        flex-shrink: 0;
    }

    &__drop-zone {
        position: relative;
        z-index: 0;
        height: 8px;
        margin-top: -4px;
        margin-bottom: -4px;
        background-position: 100%;
        transition: background-position 0.5s;
    }

    &_drag-active &__drop-zone {
        z-index: 2;

        &_enable {
            background: var(--color-primary-background)
                linear-gradient(
                    90deg,
                    var(--color-primary-background) 0%,
                    var(--color-primary-background) 50%,
                    var(--color-white) 100%
                );
            background-position: 100%;
            background-size: 400% 100%;
        }

        &_active {
            background-position: 0%;
        }

        &:not(&_enable) {
            background: unset;
        }
    }

    &:empty {
        display: none;
    }
}

.search-input-group {
    display: flex;
    min-width: 0;
    flex-wrap: nowrap;

    &__input-wrapper {
        position: relative;
        min-width: 0;
        flex: 1 1 auto;

        &:hover,
        &:focus-within {
            z-index: 1;
        }
    }

    &__mode-switch {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
    }

    &__input {
        width: calc(100% + 2px);
        padding-right: 56px;
        border-radius: 0;
        margin: 0 -1px;
        text-overflow: ellipsis;

        &_semantic {
            padding-left: 56px;
        }
    }

    &__date-filter {
        max-width: 215px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        &:hover,
        &:focus-within {
            z-index: 2;
        }
    }

    &__icon-buttons {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        flex-wrap: nowrap;
        padding: 8px;
        gap: 8px;
        transform: translateY(-50%);
    }

    &__icon-button {
        i {
            font-size: 0.875rem;
        }
    }

    &__advanced-filter {
        display: flex;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        justify-content: center;
        padding: 0;
        border-radius: 0;
    }

    &__search_button {
        display: flex;
        width: 40px;
        flex-shrink: 0;
        justify-content: center;
        padding: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    &__icon {
        display: block;
        width: 16px;
        height: 16px;
        color: var(--color-grey-icon);

        &:hover {
            color: $primaryColor;
        }

        &_blue {
            color: $primaryColor;
        }

        &_search {
            width: 24px;
            height: 24px;
            color: var(--color-white);

            &:hover {
                color: var(--color-white);
            }
        }
    }
}

.search-header-filter {
    padding: $grid-size;

    &__string-filter {
        & input,
        .p-dropdown {
            width: $grid-size * 90;
            min-width: 0;
        }
    }

    &__button {
        width: $grid-size * 10;
        height: $grid-size * 10;
    }
}

.search-input-tool {
    display: flex;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    justify-content: center;
    padding: 0;
    border-radius: 0;

    &__icon {
        display: block;
        width: 16px;
        height: 16px;
        color: var(--color-grey-icon);
    }
}

.search-channel-filter {
    width: 190px;
    flex-shrink: 0;
}

.search-stored-query-filter {
    width: 270px;
    flex-shrink: 0;

    &_full-width {
        width: 100%;
    }
}

.chart {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &_table {
        overflow: auto;
        height: stretch;
    }

    &__fake {
        &_sticky {
            position: sticky;
            z-index: 2;
            left: 0;
            margin-bottom: $grid-size * 8;
        }
    }

    &__placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;

        & svg {
            width: 100%;
            max-width: 160px;
            height: 100%;
            max-height: 160px;
            color: var(--color-grey-disabled);
        }
    }
}

.chart-type-groups {
    position: relative;

    &__type {
        overflow: hidden;
        cursor: pointer;
    }

    &__title {
        width: $grid-size * 82;
    }

    &__chart {
        overflow: hidden;
        width: $grid-size * 82;
        height: $grid-size * 58;
        border: 1px solid var(--color-grey-border);
        border-radius: 4px;
        border-radius: $grid-size;
        background-color: var(--color-white);
        pointer-events: none; // TODO - из-за этого не работает hover - сиена цвеа рамки

        &:hover {
            border-color: var(--color-primary);
        }

        &_choosen {
            border-color: var(--color-primary-active);
        }
    }
}

.chart-metric {
    padding: $grid-size * 2;
    cursor: pointer;
    text-align: center;
    word-break: break-word;

    &__element {
        max-width: 100%;
        font-size: var(--size);
        line-height: calc(var(--size) + $grid-size * 2);

        &:empty {
            display: none;
        }
    }

    &__trend {
        color: green;
        font-size: calc(var(--size) / 2);

        &_negative {
            color: var(--color-assertive);
        }

        & > i {
            font-size: calc(var(--size) / 3);
        }
    }
}

.chart-rating {
    &__index {
        width: 15 * $grid-size;
        min-width: 15 * $grid-size;
    }

    &__score {
        min-width: 30 * $grid-size;
    }
}

.code-mirror-custom {
    height: 500px;
    border: $inputBorder;
    border-radius: $borderRadius;
    appearance: none;
    background: $inputBg;
    transition: $formElementTransition;

    .cm-editor {
        height: 100%;
    }

    .cm-focused {
        outline: none;
    }

    &:hover {
        border-color: $inputHoverBorderColor;
    }

    &:focus-within {
        border-color: $inputFocusBorderColor;
        box-shadow: $focusShadow;
        outline: $focusOutline;
        outline-offset: $focusOutlineOffset;
    }

    .CodeMirror.cm-s-mdn-like {
        background-image: unset;
    }

    .cm-content {
        padding: $inputPadding;
        line-height: $lineHeight;
    }

    &_invalid {
        border-color: var(--color-assertive);

        &:focus-within,
        &:hover {
            border-color: var(--color-assertive);
            box-shadow: none;
            outline: none;
            outline-offset: none;
        }
    }

    &_resizable {
        overflow: hidden;
        resize: vertical;
    }

    &_small-height {
        height: 140px;
    }

    &_medium-height {
        height: 240px;
    }

    &_single-line {
        height: 40px;

        .cm-editor {
            overflow: hidden;
        }

        .cm-scroller {
            height: calc(
                100% + $scroll-size-height
            ) !important; // Вынужденная мера, в самом codemirror теперь тоже стоит !important

            margin-bottom: -$scroll-size-height;
            overflow-x: scroll;
            overflow-y: hidden;
        }

        .cm-activeLine {
            background-color: transparent;
        }

        .cm-placeholder {
            color: var(--color-grey-placeholder);
            font-family: var(--default-font);
        }
    }
}

.codemirror-error-mark {
    background: var(--color-assertive-disabled);
}

.codemirror-error-block {
    height: 18px;
}

.check-list-settings-dialog {
    width: 90%;
    max-width: 90%;
    height: 90%;
    max-height: 90%;
}

.check-list-settings-dialog__content {
    height: 100%;
    flex: 1 1 auto;
}

.check-list-settings {
    --level: 0;

    &__row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 8px 0;
        padding-left: calc((var(--level)) * 32px);
        gap: 16px;

        &_item {
            padding-right: 348px;
        }

        &:hover {
            background-color: var(--color-grey-background);
        }
    }

    &__test-result-content {
        display: grid;
        align-items: center;
        column-gap: 16px;
        grid-template-columns: min-content min-content auto;
    }

    &__row-title-container {
        color: var(--color-grey-text);
        font-size: 14px;
        line-height: 1;
    }

    &__row-id {
        margin-top: 2px;
        color: var(--color-grey-placeholder);
        font-size: 8px;
        font-weight: normal;
    }

    &__row-input {
        width: 120px;
    }

    &__row-action {
        width: 100px;
        flex-shrink: 0;
        text-align: center;
    }

    &__row-status {
        width: 100px;
        flex-shrink: 0;
        text-align: center;
    }

    &__row-toggle {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        color: var(--color-grey-icon);
    }
}

.check-list-panel {
    overflow: auto;
    height: 100%;
    padding: 0 20px 20px;

    &_left {
        border-right: 1px solid var(--color-grey-border);
    }
}

.check-list-title {
    padding: 23px 20px 40px;
}

.checklist-page-panel {
    width: 500px;
}

.check-list-text-test {
    height: 100%;
    overflow-y: auto;
}

.checklist-item-range {
    width: 160px;

    // TODO попробовать добиться одинаковых свойств для хрома и мозиллы
    &::-webkit-meter-optimum-value {
        border-radius: 2px;
        background: var(--color-green-hover);
    }

    @-moz-document url-prefix() {
        height: 7px;
        border: 1px solid var(--color-grey-disabled);
        border-radius: 9px;
        background: var(--color-grey-background);

        &::-moz-meter-bar {
            border-radius: 4px;
            background: var(--color-green-hover);
        }
    }
}

.highlight-navigate {
    position: absolute;
    bottom: 8px;
    left: 40px;
    display: flex;
    max-height: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    border-radius: 6px;
    background: var(--color-white);
    box-shadow: 0 0 8px var(--color-grey-placeholder);
    gap: 20px;

    &__query {
        overflow: hidden;
        width: 200px;
        color: var(--color-grey-title);
        font-size: 0.875rem;
        line-height: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-left: 1px solid var(--color-grey-border);
    }
}

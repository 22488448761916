.row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    &_relative {
        position: relative;
    }

    &_reverse {
        flex-direction: row-reverse;
    }

    &_tall {
        height: 100%;
        max-height: inherit;
    }

    &_col {
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &_wide {
        flex: 1 1 auto;
    }

    &_fit {
        width: fit-content;
    }

    &_scrollable {
        overflow: auto;
    }

    &_between {
        justify-content: space-between;
    }

    &_top {
        align-items: flex-start;
    }

    &_bottom {
        align-items: flex-end;
    }

    &_content-bottom {
        align-content: flex-end;
    }

    &_center {
        justify-content: center;
    }

    &_col#{&}_center {
        align-items: center;
    }

    &_right {
        justify-content: flex-end;
    }

    &_stretch {
        align-items: stretch;
    }

    &_nowrap {
        flex-wrap: nowrap;
    }

    &_noshrink {
        flex-shrink: 0;
    }

    &_delimiter {
        border-left: 1px dashed color(stable_1);
    }

    @for $i from 1 through 16 {
        &_offset-#{$i} {
            margin-top: $grid-size * $i;

            &:last-child {
                margin-bottom: $grid-size * $i;
            }
        }

        &_box-#{$i} {
            padding: $grid-size * $i;
        }
    }

    &_offset-clear:last-child {
        margin-bottom: 0;
    }

    &_box-clear-top {
        padding-top: 0;
    }

    &_box-clear-bottom {
        padding-bottom: 0;
    }

    &_min-width-0 {
        min-width: 0;
    }

    &_no-empty:empty {
        display: none;
    }

    @for $i from 0 through 15 {
        &_gap-#{$i} {
            gap: #{$i * $grid-size};
        }
    }
}

.col {
    width: auto;
    height: 100%;
    flex: 0 1 auto;
    align-self: auto;
    order: 0;

    &_center {
        height: auto;
        align-self: center;
    }

    &_bottom {
        height: auto;
        align-self: flex-end;
    }

    &_wide {
        flex: 1 1 auto;
    }

    &_independent {
        flex-basis: 0;
    }

    &_noshrink {
        flex-shrink: 0;
    }

    &_right {
        align-self: flex-end;
    }

    &_stretch {
        height: auto;
        align-self: stretch;
    }

    &_relative {
        position: relative;
    }

    &_cropped {
        min-width: 0;
    }

    @for $i from 2 through 12 {
        &_#{$i} {
            flex-basis: calc(100% / $i);
        }
    }

    @for $i from 1 through 16 {
        &_offset-#{$i} {
            margin-left: $grid-size * $i;

            &:last-child {
                margin-right: $grid-size * $i;
            }
        }

        &_box-#{$i} {
            padding: $grid-size * $i;
        }
    }

    &_delimiter {
        border-left: 1px dashed color(stable_1);
    }

    &_offset-clear {
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &_no-empty:empty {
        display: none;
    }
}

.grid {
    $lastColumnSize: 40px; // Размер квадратной иконки
    $gap: calc($grid-size * 8);

    display: grid;
    width: 100%;
    height: min-content;
    column-gap: $gap;
    grid-template-columns: 1fr;
    row-gap: $gap;

    @for $i from 1 through 10 {
        &_col-#{$i} {
            grid-template-columns: repeat(#{$i}, 1fr);
        }

        &_col-#{$i}-last-min {
            grid-template-columns: repeat(calc(#{$i} - 1), 1fr) min-content;
        }

        &_col-#{$i}-first-last-min {
            grid-template-columns:
                min-content repeat(calc(#{$i} - 2), 1fr)
                min-content;
        }
    }

    @for $i from 0 through 20 {
        &_cg-#{$i} {
            column-gap: #{$i * $grid-size};
        }

        &_rg-#{$i} {
            row-gap: #{$i * $grid-size};
        }
    }

    &_fit-content {
        width: fit-content;
    }

    &_center {
        align-items: center;
    }

    &_end {
        align-items: end;
    }

    &_start {
        align-items: start;
    }

    &_col-3-last-plus {
        grid-template-columns: repeat(2, 1fr) $lastColumnSize;
    }

    &_col-3-last-plus-1 {
        grid-template-columns: repeat(2, 1fr) $lastColumnSize 1fr;
    }

    &_col-4-last-plus {
        grid-template-columns: repeat(3, 1fr) $lastColumnSize;
    }

    &_col-5-last-color-plus {
        grid-template-columns: repeat(3, 1fr) $lastColumnSize $lastColumnSize;
    }

    &_col-4-last-plus-minus {
        grid-template-columns: repeat(3, 1fr) $lastColumnSize * 2;
    }

    &_col-5-last-plus {
        grid-template-columns: repeat(4, 1fr) $lastColumnSize;
    }

    &_col-two-of-three {
        grid-template-columns: 2fr 1fr;
    }

    &_full-height {
        height: 100%;
    }

    &_justify-items-start {
        justify-items: start;
    }

    &_justify-items-center {
        justify-items: center;
    }

    &_self-align-center {
        align-self: center;
    }

    &_min-content {
        width: min-content;
    }

    &_span-2 {
        grid-column: span 2;
    }

    &_rows-min-content {
        grid-template-rows: min-content;
    }

    &:empty {
        display: none;
    }
}

.grid-content {
    &_span-3 {
        grid-column-end: span 3;
    }
}

.col-count {
    width: 100%;
    column-count: 1;

    @for $i from 1 through 5 {
        &_#{$i} {
            column-count: #{$i};
        }
    }

    &__item {
        break-inside: avoid;
    }
}

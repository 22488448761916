.help-content {
    display: grid;
    height: 100%;
    grid-template-columns: 415px 5fr;

    sub {
        font-size: smaller;
    }

    &__navigate-bar {
        border-right: 1px solid var(--color-grey-border);
    }

    &__navigate-element {
        display: block;
        padding: 12px 40px;
        color: var(--color-grey-text);
        font-size: 14px;

        &_active {
            background: var(--color-primary-background);
            color: var(--color-primary);
        }
    }

    &__view {
        padding: 5px 40px 16px;
        overflow-y: auto;
    }

    &__title {
        margin-bottom: 30px;
        font-size: 16px;
    }

    &__sub-title {
        margin-bottom: 12px;
        font-size: 18px;
    }

    &__list {
        margin: 20px 15px;
        list-style: unset;
    }

    &__item {
        margin: 5px;
    }

    &__highlight {
        background-color: var(--color-assertive-background);
        color: var(--color-assertive);
    }

    &__block-text {
        padding: 9px;
        border: 1px solid var(--color-grey-border);
        background: var(--color-grey-background);
    }
}

.help-icon {
    width: 16px;
    height: 16px;
    color: var(--color-grey-icon);
}

.help-link {
    &:hover,
    &:hover .help-icon {
        color: var(--color-primary);
    }
}

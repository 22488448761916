.downloads-table {
    table-layout: fixed;

    thead {
        tr {
            th {
                &:nth-child(1) {
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 600px;
                }

                &:nth-child(3) {
                    width: 200px;
                }

                &:nth-child(4) {
                    width: 200px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                &:nth-child(1) {
                    text-align: left;
                }
            }
        }
    }
}

.icon-filters {
    &__button {
        width: 18px;
        height: 18px;

        &:disabled {
            cursor: default;
        }

        &_label {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }

        svg {
            width: 100%;
            height: 100%;
            color: var(--color-grey-icon);
        }

        &_positive {
            & svg {
                color: var(--color-green-main);
            }
        }

        &_negative {
            & svg {
                color: var(--color-assertive);
            }
        }

        &_favorite {
            & svg {
                color: var(--color-gold-main);
            }
        }
    }
}

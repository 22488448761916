.help-player {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    &__title {
        margin-bottom: 8px;
    }

    &__player-elements {
        & > span:nth-child(n + 2),
        & > div {
            height: 50px;
            padding: 5px 0;
            border-bottom: 1px solid var(--color-grey-border);
        }
    }

    &__keys {
        span:not(:nth-child(1)) {
            padding: 12px 0;
            border-bottom: 1px solid var(--color-grey-border);
        }
    }

    &__tooltip-label {
        width: 16px;
        height: 16px;
        border: 1px solid var(--color-primary);
        border-radius: 50%;
        color: var(--color-primary);
        text-align: center;

        &_painted {
            background: var(--color-primary);
            color: var(--color-white);
        }
    }

    &__tooltip-oscilogramma {
        display: inline-block;
        width: 4px;
        margin-right: 1px;
        background: var(--color-primary);
        vertical-align: bottom;
    }

    &__tooltip-oscilogramma:nth-child(1) {
        height: 7px;
    }

    &__tooltip-oscilogramma:nth-child(2) {
        height: 13px;
    }

    &__tooltip-oscilogramma:nth-child(3) {
        height: 11px;
    }

    &__tooltip-oscilogramma:nth-child(4) {
        height: 14px;
    }

    &__tooltip-oscilogramma:nth-child(5) {
        height: 9px;
    }

    &__tooltip-oscilogramma:nth-child(6) {
        height: 6px;
    }

    &__tooltip-pause {
        width: 40px;
        height: 40px;
        background: var(--color-primary-background);
    }

    &__tooltip-interrupt {
        width: 40px;
        height: 40px;
        background: var(--color-assertive-background);
    }

    &__tooltip-hold {
        color: var(--color-primary);
        font-weight: bold;
    }

    &__tooltip-speed {
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-primary);
        border-radius: 2px;
        text-align: center;
    }
}

.entity-forms {
    width: 100%;
    table-layout: fixed;

    th {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-grey-border);
        text-align: left;

        &:first-child {
            width: 400px;
        }
    }

    td {
        padding: 20px 0;
    }

    tbody {
        tr {
            border-bottom: 1px solid var(--color-grey-border);
        }
    }
}

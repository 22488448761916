.orgstructure-icons {
    &__issue {
        width: 40px;
        min-width: 40px;
        height: 20px;
        padding: 0 10px;
        color: var(--color-orange);

        &_small {
            width: 16px;
            min-width: 0;
            height: 16px;
            padding: 0;
        }
    }

    &__archive {
        width: 16px;
        height: 16px;
    }

    &__dragnDrop {
        width: 24px;
        height: 24px;
    }

    &__clear {
        width: 12px;
        height: 12px;
    }
}

.orgstructure-table-container {
    padding-top: $grid-size * 6;
}

.orgstructure-table {
    th,
    td {
        &:first-child {
            text-align: left;

            & > span {
                justify-content: flex-start;
            }
        }
    }
}

.orgstructure-map-container {
    padding: $grid-size * 6 $grid-size * 4;
}

.orgstructure-card {
    width: $grid-size * 98;
    height: $grid-size * 50;
    padding: $grid-size * 8;
    background-color: var(--color-grey-background);

    &__field {
        width: $grid-size * 75;

        /* stylelint-disable-next-line no-descending-specificity */
        & span:first-child {
            width: $grid-size * 28;
        }
    }
}

.orgstructure-additional-data-toggle {
    &__open {
        position: absolute;
        right: 0;
        bottom: 200px;
        padding: 5px;
        border: 1px solid var(--color-grey-border);
        background-color: var(--color-white);
    }

    &__close {
        position: absolute;
        z-index: 2;
        top: 50px;
        right: 310px;
        cursor: pointer;
    }
}

.orgstructure-additional-data {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    bottom: 0;
    display: inline-block;
    width: 300px;
    border-left: 1px solid var(--color-grey-border);
    background-color: var(--color-white);
    overflow-y: auto;

    &__head {
        display: flex;
        width: 300px;
        justify-content: space-between;
        padding: 10px;
        background-color: color(light_3, 0.2);
        cursor: pointer;

        &_title {
            display: flex;
            gap: 10px;
        }

        &:hover {
            background-color: color(light_4, 0.5);
        }
    }

    &__item {
        display: flex;
        padding: 5px 0 5px 30px;
    }
}

.header-project-select {
    position: relative;
    width: 350px;
    height: 100%;

    &__select {
        position: relative;
        display: block;
        width: 350px;
        height: 100%;
        align-self: flex-start;
    }

    &__select-arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -5px;
        color: var(--color-white);
        font-size: 12px;
    }

    &__select-value {
        width: 100%;
        height: 100%;
        padding: 0 30px 0 20px;
        background: var(--color-primary-dark);
        cursor: pointer;
        font-size: 0.875rem;
        text-align: left;

        &:hover {
            background: var(--color-primary-darkest);
        }
    }

    &__select-overlay {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        background: var(--color-grey-overlay);
    }

    &__select_opened &__select-overlay {
        display: block;
    }

    &__select-list {
        position: absolute;
        z-index: 10000;
        top: 100%;
        left: 0;
        display: none;
        overflow: auto;
        width: 350px;
        max-height: 80vh;
        padding: 0.5rem 0;
        border-radius: 2px;
        background: var(--color-white);
        box-shadow: 0 0 8px var(--color-grey-placeholder);
        font-size: 0.875rem;
        line-height: 1.125rem;
    }

    &__select_opened &__select-list {
        display: block;
    }

    &__select-list-collection {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__select-list-item {
        display: block;
        width: 100%;
        padding: 0.4375rem 1.5rem;
        color: var(--color-grey-text);
        cursor: pointer;
        text-align: left;

        &_collection {
            padding-left: 1.5rem;
            font-weight: bold;

            &:disabled {
                &:hover {
                    background-color: transparent;
                    color: var(--color-grey-text);
                    cursor: default;
                }
            }
        }

        &_no-collection {
            padding-left: 1.5rem;
        }

        &:visited {
            color: var(--color-grey-text);
        }

        &:hover,
        &:hover:visited {
            background: var(--color-primary-background-hovered-light);
            color: var(--color-primary);
        }

        &_active,
        &_active:visited {
            background: var(--color-primary-background);
            color: var(--color-primary);
        }

        &_default {
            position: relative;
            border-bottom: 1px solid var(--color-grey-border);
            margin-bottom: 0.5rem;
            font-weight: bold;
            text-align: center;
        }

        &_no-project {
            border: unset;
            margin-bottom: unset;
        }
    }
}

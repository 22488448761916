.badges-edit {
    position: relative;

    &__hidden {
        visibility: hidden;
    }
}

.badges-edit-button {
    z-index: 5;
    background-color: white;

    &_previous {
        position: sticky;
        top: 0;
    }

    &_next {
        position: sticky;
        bottom: 0;
    }
}

.badges-edit-right-panel {
    width: 320px;
    height: 100%;
    max-height: 100%;

    &__header {
        padding: 16px 0 8px 32px;
        font-weight: 700;
        text-transform: unset;
    }

    &__section-content {
        display: flex;
        overflow: auto;
        max-height: 218px;
        flex-flow: column nowrap;
        margin: 4px 0;

        &:empty {
            display: none;
        }
    }

    &__section-header {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        padding: 8px 0;
    }

    &__section-switcher {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        padding: 0 4px;
        color: var(--color-grey-icon);

        & > svg {
            width: 24px;
            height: 24px;
        }

        &:disabled {
            color: var(--color-grey-disabled);
        }
    }

    &__section-title {
        font-weight: 700;
        text-transform: unset;

        &:disabled {
            color: var(--color-grey-disabled);
        }
    }

    &__section-row {
        padding: 8px 12px 8px 32px;

        &_new {
            background-color: var(--color-green-hover-light);

            small {
                color: var(--color-grey-text);
            }
        }

        &_invalid {
            background-color: var(--color-gold-hover-light);
            text-decoration: line-through;

            small {
                color: var(--color-grey-text);
            }
        }

        &_deleted {
            background-color: var(--color-assertive-disabled);
            text-decoration: line-through;

            small {
                color: var(--color-grey-text);
            }
        }

        &_active {
            background-color: var(--color-primary-background);
        }
    }
}

.badges-edit-transcript {
    padding-bottom: 4 * $grid-size;
    border-bottom: 1px dotted var(--color-grey-border);

    &__header {
        height: 40px;
        margin: 8px 16px;
        grid-template-columns: 1fr 4fr 1fr;
    }

    &__highlighted-word {
        background: var(--color-gold-hover);
    }
}

.login-background {
    height: 100%;
    background-image: url('../assets/images/login-background.jpg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    &__logo {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}

.login-form {
    position: absolute;
    top: 0;
    right: 0;
    width: 500px;
    height: 100%;
    padding: 0 80px;
    background-color: var(--color-white);

    &__title {
        color: var(--color-grey-text);
        font-size: 1.5rem;
    }

    &__subtitle {
        color: var(--color-grey-placeholder);
        font-size: 0.875rem;
    }

    &__link {
        font-size: 0.875rem;
    }
}

/* Customizations to the designer theme should be defined here */
.p-datepicker {
    .p-datepicker-header {
        .p-datepicker-prev,
        .p-datepicker-next {
            color: $primaryTextColor;

            &:enabled:hover {
                color: $primaryTextColor;
                background-color: rgba(255,255,255,.2);
            }
        }
    }

    &.p-datepicker-multiple-month {
        .p-datepicker-group {
            border-right: 1px solid rgba(255,255,255,.2);
        }
    }

    .p-datepicker-buttonbar {
        border-top: 1px solid rgba(255,255,255,.2);

        .p-button {
            color: $primaryTextColor;

            &:enabled:hover {
                color: $primaryTextColor;
                background-color: rgba(255,255,255,.2);
            }
        }
    }

    .p-timepicker {
        border-top: 1px solid rgba(255,255,255,.2);

        .p-link {
            color: $primaryTextColor;

            &:enabled:hover {
                color: $primaryTextColor;
                background-color: rgba(255,255,255,.2);
            }
        }
    }

    table {
        font-size: $fontSize;
        margin: $calendarTableMargin;

        th {
            color: rgba(255,255,255,.7);
        }

        td {
            > span {
                &.p-highlight {
                    color: $primaryTextColor;
                    background: rgba(255,255,255,.3);
                }
            }

            &.p-datepicker-today {
                > span {
                    &.p-highlight {
                        color: $primaryTextColor;
                        background: rgba(255,255,255,.3);
                    }
                }
            }
        }
    }

    .p-monthpicker {
        .p-monthpicker-month {
            &.p-highlight {
                color: $primaryTextColor;
                background: rgba(255,255,255,.3);
            }
        }
    }
}

// .p-button {
//     .p-button-label {
//         font-weight: 700;
//     }
// }

.p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: $primaryColor;
    }
}

.p-galleria {
    .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background-color: $primaryColor;
    }
}

.p-datatable {
    .p-datatable-tbody {
        > tr {
            &.p-datatable-dragpoint-top > td {
                box-shadow: inset 0 2px 0 0 $primaryColor;
            }

            &.p-datatable-dragpoint-bottom > td {
                box-shadow: inset 0 -2px 0 0 $primaryColor;
            }
        }
    }
}

// Custom section starts here

.p-treeselect-panel {
    .p-treeselect-items-wrapper {
        max-height: 100vh;
    }
}

.p-component {
    line-height: $lineHeight;
}

.p-inputgroup-addon {
    padding: unset;
}

.p-tree {
    padding: unset;
}

.p-dialog {
    .p-dialog-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .p-dialog-header {
        .p-dialog-header-icon.p-dialog-header-close {
            position: absolute;
            top: 0.5rem;
            left: calc(100% + 0.5rem);
            width: $actionIconWidth;
            height: $actionIconHeight;
            color: var(--color-grey-border);
            border: unset;
            background: unset;
            border-radius: $actionIconBorderRadius;
            transition: $actionIconTransition;

            &:hover {
                color: var(--color-white);
                border-color: $actionIconHoverBorderColor;
                background: unset;
            }

            &:focus {
                outline: $focusOutline;
                outline-offset: $focusOutlineOffset;
                box-shadow: unset
            }
        }
    }

    .p-dialog-footer {
        box-shadow: 0px 0px 10px rgba(224, 224, 224, 0.4);
        gap: 0.5rem;
        display: flex;
        justify-content: flex-end;

        button {
            margin: unset;
        }
    }
}

.p-button {
    &.p-button-outlined.search-input-group__advanced-filter:not(:focus) {
        border: $inputBorder;
    }
    &.p-button-outlined.search-input-tool:not(:focus) {
        border: $inputBorder;
    }
}

.p-tooltip-light.p-tooltip {
    .p-tooltip-text {
        background: var(--color-white);
        box-shadow: 0px 0px 20px var(--color-grey-border);
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            border-right-color: var(--color-white);
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            border-left-color: var(--color-white);
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            border-top-color: var(--color-white);
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            border-bottom-color: var(--color-white);
        }
    }
}

.p-checkbox.p-checkbox-disabled {
    cursor: default;
}

.p-inputtext.p-password-input {
    width: 100%
}

.p-input-icon-left .p-inputtext,
.p-input-icon-right .p-inputtext {
    width: 100%;
}

.p-inputnumber > .p-inputtext {
    width: 100%
}

.p-menu {
    width: unset;
}

.p-contextmenu {
    width: unset;
}

.p-inputnumber {
    position: relative;

    .p-inputnumber-button-group {
        position: absolute;
        right: 0px;
        top: 0px;

        .p-inputnumber-button-up {
            padding-top: 4px;
        }

        .p-inputnumber-button-down {
            padding-bottom: 4px;
        }

        .p-inputnumber-button {
            border: unset;
            background: unset;
            color: $shade600;

            &:hover {
                color: $primaryColor;
            }
        }
    }
}

.p-colorpicker.p-colorpicker_small .p-colorpicker-preview {
    width: 1rem;
    height: 1rem;
    padding: 0;
    border-radius: 3px;
}

.p-dropdown-panel .p-dropdown-header {
    background-color: var(--color-white);
    border-bottom: 0;
}

.p-input-icon-right.p-input-icon-right_custom > svg {
    margin: 0;
    transform: translateY(-50%);
    color: var(--color-grey-icon);
}

// Prime выставляет минимальную ширину с помощью js, таким образом размер всегда будет соотвествовать минимальной ширине
.p-dropdown-panel.dropdown-tree-panel {
    max-width: 1px;
}

.statistics__action-input {
    .p-inputtext {
        width: 44px;
        max-width: 44px;
        font-size: 12px;
        line-height: 18px;
        padding: 0px 4px;
    }

    .p-inputnumber-button-group {
        right: 1px;

        .p-inputnumber-button {
            width: unset;
            padding: 0;
        }

        .p-icon {
            width: 10px;
            height: 10px;
        }
    }
}

.color-picker {
    &__preview {
        font-family: $fontFamily;
        font-size: $inputTextFontSize;
        border: $inputBorder;
        transition: $formElementTransition;
        appearance: none;
        border-radius: $borderRadius;

        &:enabled:hover {
            border-color: $inputHoverBorderColor;
        }

        &:enabled:focus {
            @include focused-input();
        }
    }

    &__palette {
        & > .p-overlaypanel-content {
            padding: 0;
        }

        & .p-colorpicker-panel {
            border-radius: 0;
            border: none;
            background-color: transparent;
        }
    }

    &__input-text {
        &.p-inputtext:enabled:focus {
            box-shadow: none;
        }
    }
}

.badges-crop-time-control {
    font-family: $fontFamily;
    font-size: $inputTextFontSize;
    color: $inputTextColor;
    background: $inputBg;
    padding: $inputPadding;
    border: $inputBorder;
    transition: $formElementTransition;
    appearance: none;
    border-radius: $borderRadius;

    &:enabled:hover {
        border-color: $inputHoverBorderColor;
    }

    &:enabled:focus {
        @include focused-input();
    }

    &_invalid {
        @include invalid-input();

        &:enabled:hover {
            @include invalid-input();
        }

        &:enabled:focus {
            @include invalid-input();
        }
    }
}

.p-datepicker {
    z-index: 100001;
}

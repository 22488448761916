.notification-page {
    position: relative;
}

.notification2-settings {
    width: 100%;
    max-height: 100%;
    padding: 0 $grid-size * 10 $grid-size * 10;
    overflow-y: auto;

    &__delete {
        padding-bottom: $grid-size * 7;
    }

    &__tab {
        padding: $grid-size * 3 0;
        border-top: 1px solid var(--color-grey-border);

        &_last {
            border-bottom: 1px solid var(--color-grey-border);
        }
    }

    &__priority {
        display: inline-block;
        height: 24px;
        padding: 0 12px;
        border-radius: 12px;
        background: var(--color-grey-background);
        color: var(--color-grey-placeholder);
        font-size: 12px;
        line-height: 24px;
        text-wrap: nowrap;
    }
}

.notification2-groups {
    display: flex;
    width: 320px;
    flex-direction: column;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 12px;
        padding-left: 10 * $grid-size;
        gap: 8px;
        line-height: inherit;

        > :nth-child(2) {
            color: var(--color-primary);
            font-weight: 700;
        }

        &:hover,
        &_active {
            background-color: var(--color-primary-background);
        }
    }
}

.notification2-table {
    /* stylelint-disable-next-line no-descending-specificity */
    th {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        text-align: left;
        vertical-align: middle;

        & span {
            justify-content: start;
        }

        &:last-child {
            & span {
                justify-content: center;
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    td {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        height: 46px;
        min-height: 46px;
        text-align: left;
        vertical-align: middle;

        &:last-child {
            text-align: center;
        }
    }

    td:nth-child(1),
    th:nth-child(1) {
        width: 50px;
        min-width: 0;
        padding: 0 16px;
    }

    td:nth-child(2),
    th:nth-child(2) {
        width: auto;
        min-width: auto;
        max-width: auto;
    }

    th:last-child,
    td:last-child {
        width: 180px;
        text-align: center;
    }

    tr &__array-cell {
        &_count {
            color: var(--color-primary);
            line-height: inherit;
        }
    }

    &__document_critical_label {
        td:nth-child(3),
        th:nth-child(3),
        td:nth-child(5),
        th:nth-child(5) {
            width: 260px;
            min-width: 260px;
            max-width: 260px;
        }
    }

    &__document_appeal {
        td:nth-child(3),
        th:nth-child(3),
        td:nth-child(4),
        th:nth-child(4) {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
        }
    }

    &__document_comment {
        td:nth-child(4),
        th:nth-child(4) {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
        }
    }

    &__document_task {
        td:nth-child(3),
        th:nth-child(3) {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }

        // td:nth-child(4),
        // th:nth-child(4),
        td:nth-child(5),
        th:nth-child(5) {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
        }
    }

    &__system {
        td:nth-child(2),
        th:nth-child(2),
        td:nth-child(4),
        th:nth-child(4),
        td:nth-child(5),
        th:nth-child(5) {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
        }
    }

    &__project_alert {
        td:nth-child(3),
        th:nth-child(3) {
            width: 260px;
            min-width: 260px;
            max-width: 260px;
        }

        td:nth-child(4),
        th:nth-child(4) {
            text-align: center;

            & span {
                justify-content: center;
            }
        }
    }
}

.search-metrics {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 24 * $grid-size;
    flex-flow: row wrap;
    gap: 4 * $grid-size;

    &__metric {
        overflow: hidden;
        width: 60 * $grid-size;
        min-width: 60 * $grid-size;
        height: 100%;
        min-height: 24 * $grid-size;
        border-radius: 4px;

        & .chart-metric {
            padding: 4 * $grid-size 3 * $grid-size;
        }

        & .am5exporting {
            display: none;
        }
    }

    &__scroll-icons {
        &_hidden {
            display: none;
        }
    }
}

.icon {
    &_inline {
        width: 1.5rem;
        height: 1.5rem;
    }

    &_border-button {
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-grey-border);
        border-radius: 2px;

        &:hover {
            color: $primaryDarkColor;
        }

        &:active {
            color: $primaryDarkerColor;
        }
    }

    &_active {
        color: $primaryDarkColor;
    }

    &_turned {
        transform: rotate(90deg);
    }

    &_block {
        display: block;
    }

    &_halo {
        padding: 15px;
        border-radius: 50%;
        background: var(--color-white);
    }

    &_flip-vertically {
        transform: scale(1, -1);
    }

    &_flip-horizontal {
        transform: scale(-1, 1);
    }

    &_offset-left {
        margin-left: 0.25rem;
    }

    &_inverse-x {
        transform: scaleX(-1);
    }

    // TODO: отрефакторить размеры
    &_12 {
        width: 12px;
        height: 12px;
    }

    &_13 {
        width: 13px;
        min-width: 13px;
        height: 13px;
    }

    &_14 {
        font-size: 14px;
    }

    &_16 {
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
    }

    &_18 {
        width: 18px;
        height: 18px;
    }

    &_25 {
        font-size: 25px;
    }

    &_36 {
        width: 36px;
        height: 36px;
    }

    &_primary {
        color: var(--color-primary);
    }

    &_grey {
        color: var(--color-grey-icon);
    }

    &_orange {
        color: var(--color-orange);
    }

    &_white {
        color: var(--color-white);
    }
}

.icon-button {
    width: 16px;
    height: 16px;

    &_large {
        width: 32px;
        height: 32px;
    }

    &_small {
        width: 12px;
        height: 12px;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    &_primary {
        color: $primaryColor;

        &:disabled {
            color: var(--color-grey-disabled);
        }

        &:hover:not(:disabled) {
            color: $primaryDarkColor;
        }
    }

    &_success {
        color: var(--color-grey-icon);

        &:disabled {
            color: var(--color-grey-disabled);
        }

        &:hover:not(:disabled) {
            color: var(--color-green-main);
        }
    }

    &_assertive {
        color: var(--color-grey-icon);

        &:disabled {
            color: var(--color-grey-disabled);
        }

        &:hover:not(:disabled) {
            color: var(--color-assertive);
        }
    }

    &_default {
        color: var(--color-grey-icon);

        &:disabled {
            color: var(--color-grey-disabled);
        }

        &:hover:not(:disabled) {
            color: $primaryColor;
        }
    }
}

.dashboard {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0 8 * $grid-size 8 * $grid-size 0;
    background-color: var(--color-grey-background);

    &__placeholder {
        width: 100%;
        height: 100%;
        background-color: var(--color-grey-background);
    }
}

.dashboard-search-panel {
    z-index: 10;
    padding: 15px;
}

.dasboard-grid-container {
    padding-right: 4 * $grid-size;
    padding-bottom: 4 * $grid-size;
    background-color: var(--color-grey-background);
    overflow-y: scroll;
}

.dashboard-grid {
    position: relative;
    width: 100%;
    height: 100%;

    &__context-menu {
        width: 100%;
        height: 100%;
    }

    &__item {
        z-index: 2;
        overflow: hidden;
        padding-top: 16px;
        padding-left: 16px;

        & .chart,
        .progress-overlay {
            border: 1px dashed var(--color-grey-icon);
            background-color: var(--color-white);
            pointer-events: none;
        }
    }

    & .react-draggable-dragging,
    & .resizing {
        z-index: 3;

        & .chart_table {
            height: auto;
        }
    }
}

.dashboard-grid-pad {
    &_inactive {
        pointer-events: none;
    }

    &__item {
        position: absolute;
        z-index: 1;
        background-color: var(--color-grey-background);
    }

    &__button {
        display: block;
        height: calc(100% - 16px);
        border: 1px dashed var(--color-grey-icon);
        margin-top: 16px;
        margin-left: 16px;
        background-color: var(--color-white);
        content: '';

        &:hover {
            border: 2px dashed var(--color-primary);
            background-color: var(--color-primary-background);
        }
    }
}

.react-grid-item {
    & > .react-resizable-handle::after {
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 10px;
        height: 10px;
        border-right: 2px solid var(--color-primary);
        border-bottom: 2px solid var(--color-primary);
        content: '';
    }
}
